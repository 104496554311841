<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [header]="toast.header"
  [ngClass]="className"
  delay="11000"
  (hide)="toastService.remove(toast)">

  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textTpl"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textTpl }}</ng-template>
</ngb-toast>
