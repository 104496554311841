<div>
<!--  <div class="-status" mat-dialog-title>-->
<!--   &lt;!&ndash; <div class="icon">-->
<!--      <svg>-->
<!--        <use xlink:href="./img/svg/booster.svg#close"></use>-->
<!--      </svg>-->
<!--    </div>&ndash;&gt;-->

<!--    <div class="status-name">{{title}}</div>-->
<!--  </div>-->
  <div class="msg" mat-dialog-content>
    <strong *ngIf="title">{{ title }}</strong>
    <p class="-lg" [innerHTML]="message"></p>
  </div>
  <!--
   <div class="msg" mat-dialog-content *ngIf="data.error.error.message">
     <strong>Message:</strong>
     <p class="-lg">{{data.error.error.message}}</p>
   </div>
   <div class="msg" mat-dialog-content *ngIf="data.error.error.errtext">
     <strong>Message:</strong>
     <p class="-lg">{{data.error.error.errtext}}</p>
   </div>
 -->


  <div mat-dialog-actions>
    <button mat-flat-button class="btn -sm -accent" (click)="closeDialog()">Close</button>
  </div>
</div>
