import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { reduce } from 'rxjs/operators';

import { AppFormGroup } from '../form.model';
import { matchValidator } from '../validators/match.validator';
import { FORM_ERRORS } from './form-errors';

@Injectable()
export class ControlService {

  constructor(private fb: FormBuilder,
              @Inject(FORM_ERRORS) private errorMsg) {
  }

  public formGroup(groups: AppFormGroup[]): FormGroup {
    const formGroup: any = {};

    if (groups && groups.length) {
      for (const group of groups) {
        for (const field of group.fields) {
          const defaultValue = field.value ? field.value : field.defaultvalue;

          switch (field.type) {
            case 'attachment':
              formGroup[field.name] = new FormControl(defaultValue || null, field.required ?
                { validators: Validators.required } : {});
              formGroup[field.name + ' caption'] = new FormControl(defaultValue || null, field.required ?
                { validators: Validators.required } : {});
              break;

            case 'boolean':
              const status = +defaultValue === 1;
              formGroup[field.name] = new FormControl(status || null, field.required ? { validators: Validators.required } : {});
              break;

            default:
              formGroup[field.name] = new FormControl(defaultValue || null, field.required ? { validators: Validators.required } : {});
          }

          // Добавление вспомогательных параметров для вызова сервиса калькуляции полей
          formGroup[field.name].calcparam = field.calcparam;
        }
      }
    }

    return this.fb.group(formGroup, {
      validator: [
        matchValidator('password', 'confirmpassword'),
        matchValidator('newpassword', 'confirmpassword'),
      ]
    });
  }

  public setServerErrors(controls: FormGroup, errors) {
    const subscription$ = from(errors)
      .pipe(
        reduce<{ [key: string]: string }>(
          (acc, current) => {
            acc[current.key] = current.value;
            return acc;
          },
          {}
        ),
      )
      .subscribe(serverErrors => {
        console.log('subscribe serverErrors', serverErrors);
        for (const fieldName in serverErrors) {
          if (serverErrors.hasOwnProperty(fieldName)) {
            const control = controls.get(fieldName);

            control.reset(control.value);
            control.markAsTouched();
            control.setErrors({
              ...control.errors,
              serverError: serverErrors[fieldName]
            });
            console.log('control', fieldName, control);
          }
        }
      });

    subscription$.unsubscribe();
  }

  public getErrorMsg(control: AbstractControl): string | boolean {
    const controlErrors = control.errors;
    if (controlErrors) {
      const firstKey = Object.keys(controlErrors)[0];
      return controlErrors['serverError'] ? controlErrors['serverError'] : this.errorMsg[firstKey](controlErrors[firstKey]);
    }
    return false;
  }
}
