import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import {RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS} from 'ng-recaptcha';

import { FormComponent } from './form/form.component';
import { FormGroupsComponent } from './form-groups/form-groups.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { FormMethodsComponent } from './form-methods/form-methods.component';
import { FormSignComponent } from './form-sign/form-sign.component';
import { FormService } from './form-services/form.service';
import { ControlService } from './form-services/control.service';
import { FormSubmitDirective } from './directives/form-submit.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { ControlErrorContainerDirective } from './directives/control-error-container.directive';
import { ControlErrorsDirective } from './directives/control-errors.directive';
import { ControlErrorComponent } from '@app/library/form/control-error/control-error.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    NgbPopoverModule,
    MatIconModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule
  ],
  declarations: [
    FormComponent,
    FormGroupsComponent,
    FormFieldComponent,
    FormMethodsComponent,
    FormSignComponent,
    ControlErrorComponent,
    FormSubmitDirective,
    DisableControlDirective,
    ControlErrorContainerDirective,
    ControlErrorsDirective,
  ],
  providers: [
    FormService,
    ControlService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' }, // Secret key: 6LeIxAcTAAAAAGG-vFI1TnRWxMZNFuojJ4WifJWe
    },
  ],
  entryComponents: [
    ControlErrorComponent,
  ],
  exports: [
    FormComponent,
    ControlErrorComponent,
    FormSubmitDirective,
    DisableControlDirective,
    ControlErrorContainerDirective,
    ControlErrorsDirective,
  ],
})
export class AppFormModule {
}
