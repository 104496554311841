<div class="contact-page">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="information">
          <h2>Information</h2>

          <!--          <div class="row no-gutters">-->
          <!--            <div class="col-3">-->
          <!--              <span class="label">Phone:</span>-->
          <!--            </div>-->
          <!--            <div class="col-4">-->
          <!--              <span class="value"><a href="tel:+372 635-51-08">+372 635-51-08</a></span>-->
          <!--            </div>-->
          <!--          </div>-->

          <div class="row no-gutters">
            <div class="col-3">
              <span class="label">Email:</span>
            </div>
            <div class="col">
              <span class="value"
                ><a href="mailto:info@cryptus.io">info@cryptus.io</a></span
              >
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-3">
              <span class="label">Address:</span>
            </div>
            <div class="col">
              <span class="value">Narva Road 7B</span>
              <span class="value">10117 Tallinn Estonia</span>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="col">-->
      <!--        <div class="contact-us">-->
      <!--          <h2>Contact us</h2>-->
      <!--            <app-form [formData]="formData" formSubmitAction="noSending" submitButtonName="Send" (submitEvent)="submit($event)"></app-form>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</div>
