import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AppFormData, AppFormField, AppFormGroup, PrepareForSignature} from '../form.model';
import {ControlService} from './control.service';

import {AppStateService} from '@app/core/services/app-state.service';

@Injectable()
export class FormService {
  public condition: BehaviorSubject<any> = new BehaviorSubject(undefined);

  constructor(private appState: AppStateService,
              private http: HttpClient,
              private controlService: ControlService) {
  }

  public addCondition(field: string, value: any) {
    this.condition.next({fieldName: field, fieldValue: value});
  }

  public getCondition(): BehaviorSubject<any> {
    return this.condition.value;
  }

  public getMetaDocument(url: any): Observable<AppFormData> {
    return this.http.get<AppFormData>(url)
      .pipe(
        map(response => this.checkFields(response))
      );
  }

  private checkFields(data: AppFormData): AppFormData {
    data.groups.forEach(group => {
      group.fields.forEach(field => {
        field.required = +field.required;
        field.hidden = +field.hidden === 1;
        field.calcparam = +field.calcparam === 1;

        // устранение пустого значения type boolean
        if (field.type === 'boolean') {
          field.value = field.value || field.defaultvalue || '0'; // false
          field.defaultvalue = field.defaultvalue || '0';
        }


        // Конвертируем тип поля для блоков заголовков полей
        /* if (field.canchange === false && ['string', 'money'].some(t => field.type === t)) {
          field.subtitle = field.type;
          field.type = 'subtitle';
        } */

        // Поиск названия группы для для поля в условиях
        if (field.conditions && field.conditions.length) {
          for (const condition of field.conditions) {
            const conditionField: AppFormField = this.findField(condition.parentfield, data);
            if (!!conditionField) {
              if (+condition.value === +conditionField.value) {
                switch (condition.action) {
                  case 'hide':
                    field.hidden = true;
                    break;
                  case 'show':
                    field.hidden = false;
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
      });
    });

    return data;
  }

  public findField(fieldName: string, data: AppFormData): AppFormField {
    let foundField: AppFormField;
    for (const group of data.groups) {
      for (const field of group.fields) {
        if (field.name === fieldName) {
          foundField = field;
          break;
        }
      }
    }

    return foundField;
  }

  public applyLayout(data: AppFormData, layout: AppFormGroup[]): AppFormData {
    const groups: AppFormGroup[] = [];

    layout.forEach(g => {
      const group: AppFormGroup = {fields: []};
      group.caption = g.caption;
      group.hideCaption = g.hideCaption;

      if (g.fields) {
        g.fields.forEach(f => {
          let field: AppFormField = {};

          data.groups.forEach((gd, igd) => {
            gd.fields.forEach((fd, ifd) => {
              if (fd.name === f.name) {
                field = {...fd, styles: f.styles};
                gd.fields.splice(ifd, 1);

                if (!gd.fields.length) {
                  data.groups.splice(igd, 1);
                }
              }
            });
          });

          group.fields.push(field);
        });
      }

      groups.push(group);
    });

    data.groups = groups.concat(data.groups);
    console.log('data', data);
    return data;
  }

  public createFlatControlsGroup(groups: AppFormGroup[]): FormGroup {
    return this.controlService.formGroup(groups);
  }

  public sendSMS(postData: PrepareForSignature): Observable<any> {
    return this.http.post(`document/prepareForSignature`, postData);
  }

}
