import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingSpinner, ILoadingSpinner } from '@app/models/loading-spinner.model';

const defaultSpinner = new LoadingSpinner({ isShown: false });

@Injectable({ providedIn: 'root' })
export class LoadingSpinnerService {
  private spinnerSubject = new BehaviorSubject(defaultSpinner);

  public getSpinner() {
    return this.spinnerSubject.asObservable();
  }

  public show(spinner?: ILoadingSpinner) {
    this.spinnerSubject.next(new LoadingSpinner({ ...spinner, isShown: true }));
  }

  public hide() {
    this.spinnerSubject.next(defaultSpinner);
  }
}
