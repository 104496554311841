import {Injectable, TemplateRef} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {cleanUpUrl} from '@app/shared/utils/url-cutter';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: any[] = [];

  constructor(private router: Router, private location: Location) {
  }

  public show(textTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textTpl, ...options});
  }

  public remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
    cleanUpUrl(this.router.url, this.location);
  }

}
