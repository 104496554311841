import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@app/core/services/theme.service';

@Component({
  selector: 'app-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.scss']
})
export class FooterLayoutComponent implements OnInit {
  public themes: string[] = [];

  constructor(private themeService: ThemeService) {
  }

  ngOnInit() {
    this.getThemes();
  }

  public selectTheme(name: string): void {
    this.themeService.setTheme(name);
  }

  public getThemes(): void {
    this.themeService.getThemes()
      .subscribe(
        themes => this.themes = themes || []
      );
  }

}
