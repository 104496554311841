import {Injectable} from '@angular/core';
import {ApiService} from '@app/core/services/api.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AccountModel, CurrencyModel} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  public refreshAccountsSubject = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService) {
  }

  public getAccounts(): Observable<AccountModel[]> {
    return this.api.get('accounts').pipe(
      map((response) => {
        response.items.map(account => {
          account.currencies = account.currencies.map(currency => {
            return new CurrencyModel(currency.id, currency.code, currency.balance, currency.available_amount, currency.balancebc);
          });
        });
        return response.items;
      })
    );
  }

}
