import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '@app/core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private api: ApiService) {
  }

  getClientInfo(): Observable<any> {
    return this.api.get('client/info');
  }

  getRegistrationInfo(): Observable<any> {
    return this.api.get('registration/info');
  }

  getClientStatus(): Observable<any> {
    return this.api.get('client/status');
  }

}
