import {FormGroup} from '@angular/forms';

export interface InitFormData {
  documenttype: string;
  id: string | number;
  guid: string;
}

export interface AppFormData {
  documenttype?: string;
  appcaption?: string;
  guid?: string;
  uid?: number;
  caption?: string;
  showform?: boolean;
  isdefault?: boolean;
  signatures?: Signature[];
  groups?: AppFormGroup[];
  methods?: AppFormMethod[];
  form?: FormGroup;
}

export interface AppFormGroup {
  id?: string | number;
  caption?: string;
  fields?: AppFormField[];
  conditions?: any[];
  hideCaption?: boolean;
}

export interface AppFormField {
  id?: string;
  name?: string;
  caption?: string;
  description?: string;
  type?: string;
  required?: boolean | number;
  regexp?: string;
  regexpexceptionmessage?: string;
  defaultvalue?: string;
  hidden?: boolean;
  tag?: string;
  canchange?: boolean;
  calcparam?: boolean;
  value?: string;
  conditions?: AppFieldCondition[];
  options?: AppFieldOption[];
  styles?: string;
  subtitle?: string;
  format?: string;
  crossBinding?: string;
  readonly?: boolean;
}

export interface AppCalculated {
  values: AppCalculatedField[]
  warnings: any;
}

export interface AppCalculatedField {
  field: string;
  value: string;
  options: any;
  values: any;
}

export interface AppFormMethod {
  id?: string;
  caption?: string;
  guid?: string;
  uid?: string;
  documenttype?: string;
  isdefault?: string;
  signaturerequired?: string;
  signatures?: Signature[];
  redirect?: boolean;
}

export interface AppFieldCondition {
  parentfield?: string;
  value?: string | number;
  action?: string;
  options?: any[];
}

export interface AppFieldOption {
  key: string;
  value: string;
}

export interface Signature {
  num?: string;
  type?: string;
  value?: string;
}

export interface PrepareForSignature {
  id: string;
  documenttype: string;
  signatureNum: string;
  guid: string;
}

export interface SignFormData {
  id: string;
  documenttype: string;
  guid: string;
  signatures: Signature[];
}

export enum ResultModel {
  Error,
  Success
}

export declare type FormMode = 'form' | 'view' | undefined;
