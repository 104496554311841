<div *ngIf="mode === 'form'" [formGroup]="form" class="field-container" [ngClass]="{'-in-column': fieldContainer}">
  <ng-container [ngSwitch]="field.type">
    <mat-form-field *ngSwitchCase="'string'" [ngClass]="{'ngHidden': field.hidden}"
                    appearance="fill" class="-field">
      <mat-label [attr.for]="field.name">{{field.caption}}</mat-label>
      <input matInput
             [formControlName]="field.name"
             [readonly]="!field.canchange"
             [required]="!!field.required"
             [placeholder]="field.description"
             (change)="fieldIsChanged(field.name, $event)"
             autocomplete="off"
             name="new-name"
             [type]="field.type"
             [id]="field.name">

      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'password'" [ngClass]="{'ngHidden': field.hidden}" appearance="fill" class="-field"
                    [ngbPopover]="field.name === 'newpassword' ? popContent : undefined">
      <mat-label [attr.for]="field.name">{{field.caption}}</mat-label>
      <input [formControlName]="field.name"
             autocomplete="new-password"
             name="new-password"
             [id]="field.name"
             [readonly]="!field.canchange"
             (change)="fieldIsChanged(field.name, $event)"
             [type]="hidePass ? 'password': 'text'" matInput>
      <!--      <button mat-icon-button matSuffix (click)="toggleHidePass($event)">-->
      <!--        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>-->
      <!--      </button>-->
      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>

      <ng-template #popContent>
        <div class="password-hints" [ngClass]="{'active': passRequirements.hasMinimum}">
          <svg class="ico">
            <use xlink:href="./img/svg/booster.svg#mark"></use>
          </svg>
          Contains at least 8 characters
        </div>
        <div class="password-hints" [ngClass]="{'active': passRequirements.hasLower}">
          <svg class="ico">
            <use xlink:href="./img/svg/booster.svg#mark"></use>
          </svg>
          Contains at lowercase letter
        </div>
        <div class="password-hints" [ngClass]="{'active': passRequirements.hasUpper}">
          <svg class="ico">
            <use xlink:href="./img/svg/booster.svg#mark"></use>
          </svg>
          Contains at uppercase letter
        </div>
        <div class="password-hints" [ngClass]="{'active': passRequirements.hasNumber}">
          <svg class="ico">
            <use xlink:href="./img/svg/booster.svg#mark"></use>
          </svg>
          Contains at least one number
        </div>
        <div class="password-hints" [ngClass]="{'active': passRequirements.hasSpecial}">
          <svg class="ico">
            <use xlink:href="./img/svg/booster.svg#mark"></use>
          </svg>
          One special character
        </div>
      </ng-template>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'text'" [ngClass]="{'ngHidden': field.hidden}" appearance="fill" class="-field -msg">
      <mat-label [attr.for]="field.name">{{field.caption}}</mat-label>
      <textarea [formControlName]="field.name"
                [readonly]="!field.canchange"
                (change)="fieldIsChanged(field.name, $event)"
                [id]="field.name"
                [type]="field.type"
                matInput
                cdkTextareaAutosize
                cdkAutosizeMinRows="5"
                cdkAutosizeMaxRows="5"></textarea>
      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-form-field>

    <!-- <ng-container *ngSwitchCase="'subtitle'">
      <ng-container *ngIf="field.defaultvalue; else tmpContainer">
        <h5 class="-controls-title">{{field.defaultvalue}}</h5>
      </ng-container>
      <ng-template #tmpContainer>
        <div class="-controls-header-row">
          <h2 class="-controls-title"><span class="subtitle">{{field.caption}}:</span></h2>
          <input [formControlName]="field.name" readonly matInput [ngClass]="{'ngHidden': field.subtitle === 'money'}">
          <span *ngIf="field.subtitle === 'money'">{{form.get(field.name).value | number: field.format}}</span>
        </div>
      </ng-template>
    </ng-container> -->

    <mat-form-field *ngSwitchCase="'money'" [ngClass]="{'ngHidden': field.hidden}"
                    appearance="fill" class="-field">
      <mat-label [attr.for]="field.name">{{field.caption}}</mat-label>
      <input matInput
            type="number"
             [required]="!!field.required"
             [formControlName]="field.name"
             (change)="fieldIsChanged(field.name, $event)"
             [id]="field.name" [type]="field.type">

      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'integer'" [ngClass]="{'ngHidden': field.hidden}"
                    appearance="fill" class="-field">
      <mat-label [attr.for]="field.name">{{field.caption}}</mat-label>
      <input matInput
             [required]="!!field.required"
             [formControlName]="field.name"
             (change)="fieldIsChanged(field.name, $event)"
             [id]="field.name" [type]="field.type">

      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'drop-down box'" [ngClass]="{'ngHidden': field.hidden}" appearance="fill"
                    class="-field">
      <mat-label [attr.for]="field.name">{{field.caption}}</mat-label>
      <mat-select [formControlName]="field.name" [id]="field.name"
                  [attr.disabled]="field.hidden == false"
                  [disabled]="!field.canchange"
                  (selectionChange)="fieldIsChanged(field.name, $event, $event.value)"
                  placeholder="Select" panelClass="ui-select">
        <mat-option *ngFor="let opt of field.options" [value]="opt.key">{{opt.value}}</mat-option>
      </mat-select>
      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-form-field>
    <mat-checkbox *ngSwitchCase="'boolean'"
                  (change)="fieldIsChanged(field.name, $event, form.get(field.name).value)"
                  [ngClass]="{'ngHidden': field.hidden}" formControlName="{{field.name}}" class=""
                  [checked]="!!field.value" [id]="field.name">{{field.caption}}
      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-checkbox>

    <ng-container *ngSwitchCase="'attachment'">
                <div class="field-label">{{field.caption}}</div>
                <div class="fe-file -field mat-form-field-appearance-fill">
                  <input #inputFile type="file" (change)="onChangeFile($event)" [id]="field.name" class="-input"/>
                  <label [attr.for]="field.name" [class.invalid]="!fileName">
                    <ng-container *ngIf="fileName; else defaultLabel">
                      <div class="in-line">
                        <strong><span>{{fileName}}</span></strong>
                        <div class="-delete-file" (click)="deleteFile($event)">
                          <svg>
                            <use xlink:href="./img/svg/booster.svg#close"></use>
                          </svg>
              </div>
            </div>
          </ng-container>
          <ng-template #defaultLabel>
            <svg class="icon-files">
              <use xlink:href="./img/svg/booster.svg#files"></use>
            </svg>
            Choose a file
            <span *ngIf="!!field.required">&nbsp;*</span>
          </ng-template>
        </label>
        <mat-error *ngIf="form.get(field.name).invalid && form.get(field.name).touched">{{error}}</mat-error>
      </div>


    </ng-container>

    <mat-form-field *ngSwitchCase="'date'" [ngClass]="{'ngHidden': field.hidden}" appearance="fill" class="-field">
      <mat-label [attr.for]="field.name">{{field.caption}}</mat-label>
      <input [matDatepicker]="myDatepicker"
             [required]="field.required"
             formControlName="{{field.name}}"
             [id]="field.name"
             matInput
             (click)="myDatepicker.open()">
      <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
      <mat-datepicker #myDatepicker></mat-datepicker>
      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-form-field>

    <mat-radio-group *ngSwitchCase="'radioGroup'" [ngClass]="{'ngHidden': field.hidden}" aria-label="Select an option"
                     (change)="fieldIsChanged(field.name, $event.value)"
                     formControlName="{{field.name}}" class="-field">
      <mat-radio-button *ngFor="let radio of field.options" [checked]="radio.key == field.value" [value]="radio.key">
        {{radio.value}} {{radio.key}}
      </mat-radio-button>
      <mat-error *ngIf="form.get(field.name).invalid">{{error}}</mat-error>
    </mat-radio-group>

    <ng-container *ngSwitchCase="'recaptcha'">
      <div class="recaptcha">
        <re-captcha (resolved)="resolved($event)" required [formControlName]="field.name"></re-captcha>
      </div>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="mode === 'view' && getValue() !== null && getValue() !== '0'" class="row">

  <div class="col-sm-4 f-view-label">
    {{field.caption}}
  </div>


  <div class="col-sm-8 f-view-data">
    <ng-container [ngSwitch]="field.type">

      <ng-container *ngIf="field.name === 'countrypep' && field.value === null">
        <strong>N/A</strong>
      </ng-container>

      <ng-container *ngSwitchCase="'boolean'">
        <ng-container [ngSwitch]="getValue()">

          <ng-container *ngSwitchCase="'1'">
            <div class="-mark-icon">
              <svg>
                <use xlink:href="./img/svg/booster.svg#mark"></use>
              </svg>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault></ng-container>

        </ng-container>
      </ng-container>


      <ng-container *ngSwitchCase="'attachment'">
        <div class="-mark-icon">
          <svg>
            <use xlink:href="./img/svg/booster.svg#mark"></use>
          </svg>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <strong>{{getValue()}}</strong>
      </ng-container>

    </ng-container>
  </div>
</div>
