export enum CryptoExchangeModel {
  Buy = 0,
  Sell = 1,
  Guest = 2
}

export enum OrderType {
  Market,
  Limit
}

export class TradeModel {
  constructor(public amount: number, public price: number) {
  }
}
