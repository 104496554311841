export class ContextModel {
  status: string;
  registrationinfo: RegistrationinfoModel;

  constructor(status: string,
              registrationinfo?: RegistrationinfoModel) {
    this.status = status;
    this.registrationinfo = registrationinfo;
  }
}

export class RegistrationinfoModel {

  constructor(public status?: string,
              public documenttype?: string,
              public objectid?: string,
              public methodguid?: string,
              public accounts?: [],
              public recentDocuments?: []) {
  }
}

