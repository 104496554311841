import {Component, OnInit} from '@angular/core';

import {Router} from '@angular/router';
import {AuthService} from '@app/core/services';
import {untilDestroyed, UntilDestroy} from '@ngneat/until-destroy';
import {UserService} from '@app/core/services/user.service';
import {statuses} from '@app/core/services/context.service';

@UntilDestroy()
@Component({
  selector: 'app-user-widget',
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.scss']
})
export class UserWidgetComponent implements OnInit {
  public name: string;
  public email: string;
  public isRegistered = false;
  public icon: string;

  constructor(private authService: AuthService,
              private router: Router,
              private userService: UserService) {
  }

  private static nameLetters(name: string): string {
    name = name.replace(/\s+/g, ' ').trim();
    if (name.split(/\s/).length > 1) {
      return name.split(/\s/).splice(0, 2).reduce((acc, word) => acc + word[0], '').toUpperCase();
    }
    return name.slice(0, 2).toUpperCase();
  }

  ngOnInit() {
    this.setUpUserWidget();
  }

  public setUpUserWidget(): void {
    const session = this.authService.getSession();
    if (session !== undefined) {
      this.userService.getClientInfo()
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.name = result.name;
          this.icon = UserWidgetComponent.nameLetters(result.name);
          this.email = result.email;
        });

      this.authService.statusAuthUser
        .pipe(untilDestroyed(this))
        .subscribe(response => {
          this.isRegistered = response === statuses.REGISTERED;
        });
    }
  }

  public logOut(): void {
    this.authService.logout();
    this.router.navigate(['/']);
  }

}
