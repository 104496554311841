import {Component, OnInit} from '@angular/core';
import {StorageInterface} from '@app/models';
import {ActivatedRoute} from '@angular/router';
import {AppStateService} from '@app/core/services/app-state.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  public data: StorageInterface;
  public correct: boolean;

  constructor(private route: ActivatedRoute,
              private appState: AppStateService) {
  }

  ngOnInit() {
    this.getData();
  }

  private getData(): void {
    // if (this.appState.storageUrlData) {
    //   this.data = {...this.appState.storageUrlData};
    //   this.appState.storageUrlData = undefined;
    // } else {
    // this.data.documenttype = this.route.snapshot.paramMap.get('documenttype');
    // this.data.id = this.route.snapshot.paramMap.get('id');
    // this.data.guid = this.route.snapshot.paramMap.get('guid');
    this.data = {
      documenttype: this.route.snapshot.paramMap.get('documenttype'),
      id: this.route.snapshot.paramMap.get('id'),
      guid: this.route.snapshot.paramMap.get('guid')
    };
    // }
    this.correct = this.checkData();
  }

  public checkData(): boolean {
    return !!this.data.id && !!this.data.guid && !!this.data.documenttype;
  }

}
