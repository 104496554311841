import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { CorraccModel } from '@app/models/corracc.model';
import { WalletModel } from '@app/models/wallet.model';

@Injectable({
  providedIn: 'root'
})
export class RequisiteService {

  constructor(private api: ApiService) {
  }

  public getCorraccs(): Observable<CorraccModel[]> {
    return this.api.get('general/query/corraccslist');
  }

  public getWalletlist(): Observable<WalletModel[][]> {
    return this.api.get('general/query/walletlist');
  }

}
