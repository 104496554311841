<div ngbDropdown class="actions-group d-inline-block">
  <div class="-dots" ngbDropdownToggle>
    <svg>
      <use xlink:href="./img/svg/booster.svg#dots"></use>
    </svg>
  </div>
  <div ngbDropdownMenu>
    <ul>
      <li>
        <button type="button" class="btn -action">
          <svg>
            <use xlink:href="./img/svg/booster.svg#statement"></use>
          </svg>
          <span>Top Up</span></button>
      </li>
      <li>
        <button type="button" class="btn -action">
          <svg>
            <use xlink:href="./img/svg/booster.svg#statement"></use>
          </svg>
          <span>Transfer</span></button>
      </li>
      <li>
        <button type="button" class="btn -action">
          <svg>
            <use xlink:href="./img/svg/booster.svg#statement"></use>
          </svg>
          <span>Statement</span></button>
      </li>
    </ul>
  </div>
</div>
