export class CurrencyModel {

  constructor(public id?: string | number,
              public code?: string,
              public balance?: string | number,
              public availableAmount?: string | number,
              public balancebc?: string | number) {
  }
}

export class CurrencyListModel {
  constructor(public code?: string[]) {
  }
}

