<div class="wrapper" [ngClass]="{ 'on-dark': isDark }">
  <div class="main-row">
    <header class="l-header container">
      <div class="row">
        <div class="-left col">
          <div class="logo-wrapper">
            <a [routerLink]="'/'" class="logo">
              <svg>
                <use xlink:href="./img/svg/booster.svg#logo"></use>
              </svg>
            </a>
            <div class="-text">Start buying and<br />selling Bitcoin now</div>
          </div>
          <!-- end logo-wrapper -->
        </div>
        <!-- end left -->

        <div>
          <!-- <button mat-flat-button class="btn -xs -secondary" (click)="toggleTheme()">Toggle Layout:
            <ng-container *ngIf="isDark; else name">Dark</ng-container>
            <ng-template #name>Light</ng-template>
          </button>-->
        </div>
        <!-- end right -->
      </div>
    </header>

    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <app-alert></app-alert>
        </div>
      </div>
    </div>

    <router-outlet></router-outlet>

    <footer class="container">
      <app-footer-layout style="display: none"></app-footer-layout>
    </footer>
  </div>
  <!-- end main-row -->
</div>
<!-- end wrapper -->
