import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AuthService } from '@app/core/services';
import { statuses } from '@app/core/services/context.service';
import {
  animate, query,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    trigger('overlay', [
      transition(':enter', [
        query('.overlay-navigation', style({opacity: 0})),
        query('.overlay-navigation', animate('500ms ease-out', style({ opacity: 1}))),
      ]),
      transition(':leave', [
        query('.overlay-navigation', animate('500ms ease-out', style({ opacity: 0}))),
      ])
    ]),
  ],
})
export class MainLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  isDark = false;
  isRegistered = false;
  isGuest = true;

  overlayNavIsOpen = false;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.statusAuthUser
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.isRegistered = response === statuses.REGISTERED;
        this.isGuest = response === 'isGuest';
      });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  toggleOverlayNav() {
    this.overlayNavIsOpen = !this.overlayNavIsOpen;
    console.log('this.overlayNavIsOpen', this.overlayNavIsOpen);
  }
}
