import {
  Component,
  Output,
  EventEmitter,
  OnDestroy, AfterViewInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { statuses } from '@app/core/services/context.service';
import { AuthService } from '@app/core/services';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'app-overlay-navigation',
  templateUrl: './overlay-navigation.component.html',
  styleUrls: ['./overlay-navigation.component.scss'],
})
export class OverlayNavigationComponent implements OnDestroy, AfterViewInit {
  @Output() onClose = new EventEmitter();

  isRegistered = false;
  isGuest = true;

  constructor(private authService: AuthService) {
    this.authService.statusAuthUser
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.isRegistered = response === statuses.REGISTERED;
        this.isGuest = response === 'isGuest';
      });
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  close() {
    this.onClose.emit();
  }
}
