import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AuthDataModel, ResultModel, UserModel } from '@app/models';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiService } from '@app/core/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { AppStateService } from '@app/core/services/app-state.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ERROR_INTERCEPTOR_SKIP_HEADER } from '@app/core/interceptors/error.interceptor';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<UserModel>({} as UserModel);
  public currentInfo$ = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private isGuestSubject = new ReplaySubject<boolean>(1);
  public isGuest = this.isGuestSubject.asObservable();

  private authData: BehaviorSubject<AuthDataModel>;

  private statusAuthUserSubject = new BehaviorSubject<any>('isGuest');
  statusAuthUser = this.statusAuthUserSubject.asObservable();

  constructor(
    private api: ApiService,
    private router: Router,
    private cookieService: CookieService,
    private appState: AppStateService,
    private http: HttpClient
  ) {
    this.isGuestSubject.next(true);
  }

  public setStatusAuthUser(status: string) {
    this.statusAuthUserSubject.next(status);
  }

  public login(data): Observable<any> {
    const headers = new HttpHeaders().append(ERROR_INTERCEPTOR_SKIP_HEADER, '');

    return this.http.post('client/login', data, { headers });
  }

  public sendCode(data, sessionId) {
    const headers = new HttpHeaders().append('x-sid', sessionId);

    return this.http.post('client/secondLogin', data, { headers });
  }

  public refreshSession() {
    const headers = new HttpHeaders().append(
      'token',
      this.appState.getRefreshToken()
    );
    return this.api.get('session/refresh', null, headers);
  }

  public logout(): void {
    this.cookieService.delete('sessionid', '/');
    this.cookieService.check('sessionid');
    this.isGuestSubject.next(true);
    this.statusAuthUserSubject.next('isGuest');
    this.setClientInfo({} as UserModel);
    this.appState.removeState();
    this.router.navigate(['/']);
  }

  public userAuthCheck(): boolean {
    const session = this.appState.getSession();
    if (session) {
      this.isGuestSubject.next(false);
      return false;
    } else {
      this.isGuestSubject.next(true);
      return true;
    }
  }

  public getSession(): string {
    const session = this.appState.getSession();
    if (session) {
      this.isGuestSubject.next(false);
      return session;
    }
  }

  public setClientInfo(result: UserModel) {
    this.currentUserSubject.next(result);
  }

  public userLogsIn(result: any): void {
    const resultStatus = Number(result.result);
    if (resultStatus === ResultModel.Success && result.sessionid) {
      this.isGuestSubject.next(false);
      this.statusAuthUserSubject.next(result.status);
      this.appState.initState(result);
    } else if (
      resultStatus === ResultModel.MustChangePassword &&
      result.sessionid
    ) {
      this.isGuestSubject.next(false);
      this.statusAuthUserSubject.next(result.status);
      this.appState.initState(result);
    } else {
      this.isGuestSubject.next(true);
    }
  }
}
