<ng-container *ngFor="let alert of alerts">
  <div class="jtx-dialog {{cssClass(alert)}}">
    <div class="alert-container">
      <div class="-status">
        <div class="status-name">
          Attention
        </div>
      </div>
      <div class="msg">

        <a class="alert-close" (click)="removeAlert(alert)">
          <svg class="ico">
            <use xlink:href="./img/svg/booster.svg#close"></use>
          </svg>
        </a>
        <ng-container *ngFor="let message of alert.message">
          <p class="-lg">{{message}}</p>
        </ng-container>

      </div>
    </div>
  </div>
</ng-container>

