<div class="container login-wrapper" @inOutAnimation>
  <div class="row">
    <div class="col-md-7 col-xl-5">
      <div class="toggle-view -in-main-page">
        <h1>Sign in</h1>
        <h2><span class="subtitle">or</span></h2>
        <button routerLink="/signup" class="btn -lg -secondary-corner">
          <span>Sign up</span>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7 col-xl-5">
      <app-form
        [formData]="formData"
        formSubmitAction="noSending"
        submitButtonName="Sign In"
        (submitEvent)="formSubmit($event)"
      ></app-form>
    </div>

    <div class="col-md-5 col-xl-5 offset-xl-1 info-container"></div>
  </div>
</div>
