<div class="user-widget" ngbDropdown placement="bottom-left" #userWidget>
  <div class="-widget-btn" ngbDropdownToggle>
    <div class="avatar d-flex align-items-center justify-content-center">
      <span>{{icon}}</span>
    </div>

    <div class="-user-data">
      <h5 class="-full-name">
        {{name}}
        <svg class="icon">
          <use xlink:href="./img/svg/booster.svg#arrow_up"></use>
        </svg>
      </h5>
      <div class="-info">{{email}}</div>
    </div>
  </div>

  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="-widget-container" [ngStyle]="{'width.px': userWidget.offsetWidth + 40}">
    <div class="-container-wrap">
      <ng-container *ngIf="isRegistered; else noRegistered">
        <ul>
          <li *ngIf="isRegistered">
            <button class="-link" ngbDropdownItem routerLink="/user/profile">Profile</button>
          </li>
          <li *ngIf="isRegistered">
            <button class="-link" ngbDropdownItem routerLink="/user/change-password">Change Login password</button>
          </li>
        </ul>
        <div class="-bottom">
          <button class="-link" ngbDropdownItem (click)="logOut()">Logout</button>
        </div>
      </ng-container>
      <ng-template #noRegistered>
        <div class="-bottom">
          <button class="-link" ngbDropdownItem (click)="logOut()">Logout</button>
        </div>
      </ng-template>


    </div>
  </div>

</div>
