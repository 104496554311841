export type Size = 'default' | 'small' | 'medium' | 'large';

export interface ILoadingSpinner {
  size?: Size;
  fullScreen?: boolean;
}

export class LoadingSpinner {
  fullScreen: boolean;
  isShown: boolean;
  size: Size;

  constructor({ fullScreen, isShown, size }: Partial<LoadingSpinner> = {}) {
    if (fullScreen !== undefined) this.fullScreen = fullScreen;
    if (isShown !== undefined) this.isShown = isShown;
    if (size !== undefined) this.size = size;
  }
}
