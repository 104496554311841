<div *ngFor="let signature of data.signatures; let i = index">
  <div *ngIf="signature.type === 'SMSPASSWORD'" class="row">
    <mat-form-field appearance="fill" class="-field col-md-6">
      <mat-label [attr.for]="'SMS' + i">SMS password</mat-label>
      <input matInput (change)="changeField()" [(ngModel)]="data.signatures[i].value" [id]="'SMS' + i" type="text" autocomplete="off">
    </mat-form-field>
    <div class="-bottom col-md-6">
      <button type="submit" mat-flat-button class="btn -lg -accent" [disabled]="!!wait" (click)="sendSMS()">
        Send SMS
      </button>
      <span *ngIf="wait" class="resend">
          Resend SMS after {{wait}} seconds
        </span>
    </div>
  </div>

  <div *ngIf="signature.type === 'PASSWORD'" class="row">
    <mat-form-field appearance="fill" class="-field col-md-12">
      <mat-label [attr.for]="'pass' + i">Password</mat-label>
      <input matInput (change)="changeField()" [(ngModel)]="data.signatures[i].value" [id]="'pass' + i" type="text">
    </mat-form-field>
  </div>
</div>
