import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  get(path: string, params: HttpParams = undefined, headers: HttpHeaders = undefined): Observable<any> {
    return this.http.get(`${path}`, { params, headers });
  }

  public post(path: string, body = {}): Observable<any> {
    return this.http.post(`${path}`, JSON.stringify(body));
  }

  put(path: string, body = {}): Observable<any> {
    return this.http.put(`${path}`, JSON.stringify(body));
  }
}
