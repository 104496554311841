import {BehaviorSubject} from 'rxjs';
import {IHttpStateInterface} from '@app/models';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpStateService {
  public state = new BehaviorSubject<IHttpStateInterface>({} as IHttpStateInterface);

  constructor() {
  }

}
