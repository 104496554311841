import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { BitcoinTopUpComponent } from '@app/modules/dashboard/accounts/bitcoin-top-up/bitcoin-top-up.component';
import { MatDialog } from '@angular/material/dialog';

type Direction = 'row' | 'column';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Input() direction: Direction = 'row';
  @Input() isRegistered: boolean;
  @Input() isGuest: boolean;

  @Output() overlayClose = new EventEmitter();

  constructor(private dialog: MatDialog) { }

  ngOnInit() {
  }

  handleClick(): void {
    this.overlayClose.emit();
  }

  openBitcoinTopUp(): void {
    this.dialog.open(BitcoinTopUpComponent, {
      width: '750px',
      panelClass: ['jtx-dialog', '-doc'],
    });
  }

}
