import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {UserService} from '@app/core/services/user.service';
import {ContextService} from '@app/core/services/context.service';
import {ContextModel} from '@app/models';
import {forkJoin} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-external-page',
  template: '',
})
export class ExternalPageComponent implements OnInit, OnDestroy {

  constructor(private userService: UserService, private contextService: ContextService) {
  }

  // Вызывается после прохождения верифа
  ngOnInit() {
    const userStatus = this.userService.getClientStatus();
    const userRegistrationInfo = this.userService.getRegistrationInfo();

    forkJoin([userStatus, userRegistrationInfo]).pipe(
      untilDestroyed(this)
    ).subscribe(result => {

      const status = result[1].status;
      const objectid = result[1].objectid; // undefined

      if (status === 'external_verification') {
        this.contextService.externalRedirectToPage(status, objectid);
      } else {
        console.log('result', result);
        this.contextService.externalRedirectToPage(status);
      }

    });
  }

  ngOnDestroy(): void {
  }
}
