import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';

import {AppFormData, AppFormField, AppFormGroup, AppFormMethod, FormMode, InitFormData, ResultModel, SignFormData} from '../form.model';
import {FormService} from '../form-services/form.service';
import {ControlService} from '../form-services/control.service';
import {FormSignComponent} from '../form-sign/form-sign.component';

import {LoadingSpinnerService} from '@app/core/services/loading-spinner.service';
import {AlertService, AuthService} from '@app/core/services';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy {
  @ViewChild(FormSignComponent) private formSign: FormSignComponent;
  @Input() public initData?: InitFormData;
  @Input() public formData?: AppFormData;
  @Input() public config?: AppFormGroup[];
  @Input() public nextPageUrl?: string;
  @Input() public prevPageUrl?: string;
  @Input() public title?: string;
  @Input() public submitButtonName?: string;
  @Input() public mode?: FormMode;
  @Input() public formSubmitAction?: string;
  @Input() public customProcessingMethod?: boolean;
  @Output() public submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() public methodEvent: EventEmitter<AppFormMethod> = new EventEmitter();
  private subscription: Subject<any> = new Subject();
  public formClass: string;
  public methods: AppFormMethod[];
  public viewTitle: string;
  public caption: string;
  public signFormData: SignFormData;
  public signatures: string[];
  public disabled: boolean;
  public currentUrl = '';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private http: HttpClient,
              private spinnerService: LoadingSpinnerService,
              private alertService: AlertService,
              private formService: FormService,
              private authService: AuthService,
              private controlService: ControlService) {
  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
    this.getFormData();
    this.checkFormMode();
  }

  private getFormData(): void {
    if (!this.formData) {
      const url = this.getFormLink();

      this.spinnerService.show();
      this.formService.getMetaDocument(url)
        .pipe(finalize(() => this.spinnerService.hide()))
        .subscribe(data => {
          console.log('+++ result +++', data);

          this.formData = data;
          this.parseFormData();
        });
    } else {
      this.parseFormData();
    }
  }

  private checkFormMode(): void {
    if (!this.mode) {
      this.mode = 'form';
      this.formClass = 'form';
    }
    if (this.mode === 'view') {
      this.formClass = 'container';
    }
  }

  private getFormLink(): string {
    // Получаем параметры при их отсутствии из url текущей страницы
    if (!this.initData) {
      this.initData = {
        documenttype: this.route.snapshot.paramMap.get('documenttype'),
        id: this.route.snapshot.paramMap.get('id'),
        guid: this.route.snapshot.paramMap.get('guid'),
      };
    }

    return 'document/' + this.initData.documenttype + '/' + this.initData.id + '/' + this.initData.guid + '/form';
  }

  private parseFormData(): void {
    this.viewTitle = this.formData.appcaption;
    this.caption = this.formData.caption;

    if (this.config) {
      this.formData = this.formService.applyLayout(this.formData, this.config);
    }

    // Методы формы
    this.methods = this.formData.methods;

    // Создаем объект с группами полей
    this.formData.form = this.formService.createFlatControlsGroup(this.formData.groups);

    this.signFormData = {
      ...this.initData,
      signatures: this.formData.signatures
    } as SignFormData;

    console.log('+++ methods +++', this.methods);
    console.log('+++ FORM +++', this.formData.form);
  }

  public handleKeyDown(event): void {
    if (event.keyCode === 13) {
      this.formSubmit();
    }
  }

  public formSubmit(): void {
    this.formData.form.markAllAsTouched();

    let bob = true;

    Object.keys(this.formData.form.controls).forEach(controlName => {
      if (controlName) {
        const control = this.formData.form.controls[controlName];
        const field = this.findField(controlName);
        if (field && !field.hidden && !control.valid) {
          console.log('field', controlName, field, control);
          bob = false;
        }
      }
    });

    console.log('bob', bob);

    if (bob || this.mode === 'view') {
      if (this.formSubmitAction === 'noSending') {
        this.submitEvent.emit(this.formData.form);
      } else {
        const formData = {
          // Добавить возможность получать данные из вне формы для отправки
          ...this.initData,
          sessionid: this.authService.getSession(),
          fields: [],
          signatures: this.signatures
        };

        Object.keys(this.formData.form.value)
          .filter(item => !item.includes('caption'))
          .forEach(item => {
            const field: any = {
              key: item,
              value: this.formData.form.value[item]
            };

            const inField = this.findField(item);

            if (!!inField) {
              if (inField && inField.type === 'boolean') {
                field.value = !!field.value ? '1' : '0';
              }
              if (inField && inField.type === 'date') {
                field.value = field.value ? moment(field.value).format('YYYYMMDD') : null;
                console.log('date', field.value);
              }
              if (inField && inField.type === 'attachment') {
                field.name = this.formData.form.value[item + ' caption'];
              }
            }

            formData.fields.push(field);
          });

        this.disabled = true;

        this.http.post<any>('document', formData)
          .pipe(
            takeUntil(this.subscription),
            finalize(() => this.formSign.clearSMSField())
          )
          .subscribe(
            result => {
              const status = Number(result.result);

              if (status === ResultModel.Error) {
                this.alertService.warning(result.message, result.fieldErrors);
              }
              if (status === ResultModel.Success) {
                console.log('------ Test:: ', result);
                // document/KYC_FullRegistration/{{id}}/{{guid}}/metadata
                // this.alertService.primary(result.message, result.fieldErrors);

                this.submitEvent.emit(result);

                if (this.nextPageUrl) {
                  if (result.additionalinfo === null) {
                    this.router.navigate([this.nextPageUrl]);
                  } else {
                    const next: InitFormData = {
                      documenttype: result.additionalinfo.documenttype,
                      id: result.additionalinfo.objectid,
                      guid: result.additionalinfo.methodGUID
                    };

                    // this.appState.updateState(next);
                    const nextUrl = '/' + next.documenttype + '/' + next.id + '/' + next.guid + '/form';
                    console.log('------ Test:: ', this.nextPageUrl + nextUrl);
                    this.router.navigate([this.nextPageUrl + nextUrl]);
                  }
                }
              }
              console.log('RESULT DATA', result);
            },
            response => {
              console.log('error form response', response);
              this.disabled = false;
              if (response.error && response.error.fieldErrors) {
                this.controlService.setServerErrors(this.formData.form, response.error.fieldErrors);
              }
            }
          );
      }
    }
  }

  private findField(name: string): AppFormField {
    for (const group of this.formData.groups) {
      for (const field of group.fields) {
        if (field.name === name) {
          return field;
        }
      }
    }
    return undefined;
  }

  public activateMethod(method: AppFormMethod) {
    method.id = '' + this.initData.id;
    if (this.customProcessingMethod) {
      this.methodEvent.emit(method);
    } else {
      /*this.appState.storageUrlData = {
        id: this.storageUrlData.id,
        guid: method.guid,
        documenttype: method.documenttype
      };*/
      this.router.navigate([`/document/${method.documenttype}/${this.initData.id}/${method.guid}`]);
    }
  }

  public changeSign(event: any) {
    console.log('changeSign', event);
    this.signatures = event;
  }

  public ngOnDestroy(): void {
    this.subscription.next();
    this.subscription.complete();
  }

}
