// custom validator to check that two fields match
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (control && matchingControl) {
      if (matchingControl.errors) {
        //  && !matchingControl.errors.mustMatch
        // return if another validator has already found an error on the matchingControl
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: 'No Password match!' });
      } else {
        matchingControl.setErrors(null);
      }
    }
  };
}

