import {Injectable} from '@angular/core';
import {StorageInterface} from '@app/models';
import {LocalStorageService} from 'ngx-webstorage';
import {CookieService} from 'ngx-cookie-service';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  public passwordRecoveryDocumentId: string;
  public storageUrlData: StorageInterface;

  constructor(private storage: LocalStorageService, private cookieService: CookieService) {
  }

  initState(data?: any): void {
    this.storage.store('page', 'welcome');
    // TODO изменить место хранения сессий
    // this.cookieService.set('sessionid', data.sessionid);
    // documenttype: "KYC_FullRegistration"
    // guid: "1210"
    // id: "{682E9153-EB20-440D-98C2-C35AFEA7F307}"
    const page: any = {
      // documenttype: 'KYC_FullRegistration',
      sessionid: data.sessionid,
      // id: '1210',
      // guid: '{682E9153-EB20-440D-98C2-C35AFEA7F307}'
      // id: '0',
      // guid: 'NEW'
      refreshtoken: data.refreshtoken,
    };
    if (data.additionalInfo !== undefined && data.additionalInfo.hasOwnProperty('redirect')) {
      console.log('REDIRECT', data.additionalInfo.redirect);
      page.redirect = data.additionalInfo.redirect;
    }
    this.storage.store('data', page);
  }

  removeState(): void {
    this.storage.clear();
  }

  getStorage() {
    return this.storage.retrieve('data');
  }

  getSession() {
    const data = this.storage.retrieve('data');
    if (data === null) {
      return false;
    } else {
      return data.sessionid;
    }
  }

  getRefreshToken() {
    const data = this.getStorage();
    if (!data) {
      return;
    }

    return data.refreshtoken;
  }

  updateState(sessionid: any): void {
    this.storage.store('sessionid', sessionid);
  }

  setThemeStorage(themeID: any): void {
    this.storage.store('themeID', themeID);
  }

  getThemeStorage() {
    const data = this.storage.retrieve('themeid');
    if (data === null) {
      return false;
    } else {
      return data;
    }

  }
}
