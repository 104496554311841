export class AlertModel {
  message: string[];
  type: AlertType;
  alertId: string;
  // keepAfterRouteChange: boolean;

  constructor(init?: Partial<AlertModel>) {
    Object.assign(this, init);
  }
}

export enum AlertType {
  Success,
  Danger,
  Primary,
  Warning
}
