
<div class="wrapper" [ngClass]="{ 'on-dark': isDark }">
  <app-overlay-navigation @overlay *ngIf="overlayNavIsOpen" (onClose)="toggleOverlayNav()"></app-overlay-navigation>
  <div class="main-row">

    <header class="l-header container-fluid">
      <div class="row">
        <div class="-left col-4 col-sm-7 col-lg-8">
          <div class="logo-wrapper">
            <ng-container>
              <a [routerLink]="isRegistered ? '/dashboard' : '/'" class="logo">
                <svg>
                  <use xlink:href="./img/svg/booster.svg#logo"></use>
                </svg>
              </a>
            </ng-container>

            <div class="-text">Start buying and<br />selling Bitcoin now</div>
          </div>
          <!-- end logo-wrapper -->

          <!--          <app-navbar [isRegistered]="isRegistered" [isGuest]="isGuest"></app-navbar>-->
        </div>
        <!-- end left -->

        <div class="-right col-8 col-sm-5 col-lg-4">
          <app-user-widget *ngIf="!isGuest"></app-user-widget>

          <div class="collapse-menu" (click)="toggleOverlayNav()">
            <button  class="pages-navigation__btn" >
              <span [class]="this.overlayNavIsOpen ? 'pages-navigation__btn-brg  pages-navigation__btn-brg-active' : 'pages-navigation__btn-brg'"></span>
            </button>
          </div>
        </div>
        <!-- end right -->
      </div>
    </header>

    <div class="content-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-7">
            <app-alert></app-alert>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>

    <footer class="container-fluid">
      <app-footer-layout></app-footer-layout>
    </footer>

  </div>

  <!-- end main-row -->
</div>
<!-- end wrapper -->
