import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '@app/core/services';
import {ToastService} from '@app/core/services/toast.service';

@Component({
  selector: 'app-topup-status',
  templateUrl: './topup-status.component.html',
  styleUrls: ['./topup-status.component.scss']
})
export class TopupStatusComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.getStatus();
  }

  public getStatus() {
    const orderId = this.route.snapshot.queryParamMap.get('order_id');

    this.api.put(`topup/verify/${orderId}`).subscribe(response => {
      const status = response.result;

      switch (status) {
        case '0':
          this.toastService.show(response.message, {header: ' '});
          break;
        case '-1':
          this.toastService.show(response.message, {header: ' '});
          break;
        case '1':
          this.toastService.show(response.message, {header: ' '});
          break;
        default:
          break;
      }
    });
  }
}
