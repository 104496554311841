import {Component, HostBinding, TemplateRef, OnInit, Input} from '@angular/core';
import {ToastService} from '@app/core/services/toast.service';


@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss']
})
export class ToastContainerComponent implements OnInit {
  @HostBinding('class.ngb-toasts') preventDefaultBehaviour = true;
  @Input() className?: string;
  public toasts: any[] = [];


  constructor(public toastService: ToastService) {
  }

  ngOnInit() {
    this.toasts = this.toastService.toasts;
  }

  public isTemplate(toast) {
    return toast.textTpl instanceof TemplateRef;
  }
}
