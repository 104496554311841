<nav
  class="navbar"
  [class.navbar-row]="direction === 'row'"
  [class.navbar-column]="direction === 'column'"
>
  <ul class="navbar-nav" ngbNav>
    <li *ngIf="isRegistered" ngbNavItem class="active">
      <a class="nav-link" routerLink="/dashboard" (click)="handleClick()">Dashboard</a>
    </li>

    <li *ngIf="isRegistered" ngbNavItem>
      <div ngbDropdown>
        <a href (click)="false" class="nav-link" ngbDropdownToggle>Accounts</a>

        <div ngbDropdownMenu>
          <ul class="sub-menu">
            <li ngbDropdown [autoClose]="false">
              <a href (click)="false" ngbDropdownToggle>
                <svg>
                  <use xlink:href="./img/svg/booster.svg#arrow2"></use>
                </svg>
                Bitcoin
              </a>

              <div ngbDropdownMenu>
                <ul>
                  <li>
                    <a ngbDropdownItem routerLink="/account/bitcoin/CRYPTO_Send/0/NEW/form" (click)="handleClick()">Transfer</a>
                  </li>
                  <li>
                    <a ngbDropdownItem routerLink="/account/bitcoin/statement" (click)="handleClick()">Statement</a>
                  </li>
                </ul>
              </div>
            </li>

            <li ngbDropdown [autoClose]="false">
              <a href (click)="false" ngbDropdownToggle>
                <svg>
                  <use xlink:href="./img/svg/booster.svg#arrow2"></use>
                </svg>
                Euro
              </a>

              <div ngbDropdownMenu>
                <ul>
                  <li>
                    <a ngbDropdownItem routerLink="/account/card-top-up/TopupFromCard/0/NEW/form" (click)="handleClick()">Top Up from card</a>
                  </li>
                  <li>
                    <a ngbDropdownItem routerLink="/account/euro/Outgoing_Payment_FReq/0/NEW/form" (click)="handleClick()">Transfer</a>
                  </li>
                  <li>
                    <a ngbDropdownItem routerLink="/account/euro/statement" (click)="handleClick()">Statement</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </li>

<!--    <li *ngIf="!isRegistered" ngbNavItem class="active">-->
<!--      <a class="nav-link" routerLink="/" (click)="handleClick()">Teaser</a>-->
<!--    </li>-->

    <li ngbNavItem>
      <div ngbDropdown>
        <a href (click)="false" class="nav-link" ngbDropdownToggle>Information</a>

        <div ngbDropdownMenu>
          <ul class="px-0">
            <li>
              <a ngbDropdownItem href="https://cryptus.io/terms.html" target="_blank">Terms and Conditions</a>
            </li>
            <li>
              <a ngbDropdownItem href="https://cryptus.io/privacy-policy.html" target="_blank">Privacy Policy</a>
            </li>
            <li>
              <a ngbDropdownItem href="https://cryptus.io/legal-framework.html" target="_blank">Legal Framework</a>
            </li>
            <li>
              <a ngbDropdownItem routerLink="/contact">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </li>

  </ul>
</nav>
