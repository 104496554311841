<div class="footer-wrapper">
  <div class="footer-line"></div>
  <div class="row justify-content-between">
    <div class="col-12 col-md-7 ">
      <div class="row footer-menu-group">
        <div class="footer-menu">
          <a href="https://cryptus.io/terms.html" target="_blank">Terms and Conditions <span>|</span></a>
        </div>
        <div class="footer-menu">
          <a href="https://cryptus.io/privacy-policy.html" target="_blank">Privacy Policy <span>|</span></a>
        </div>
        <div class="footer-menu">
          <a href="https://cryptus.io/legal-framework.html" target="_blank">Legal Framework <span>|</span></a>
        </div>
        <div class="footer-menu">
          <a [routerLink]="'/contact'">Contact</a>
        </div>
      </div>
      <div class="row footer-menu-group">
        <div class="footer-menu">
          <img class="master-card" src="./img/mastercard-footer.png" alt="">
        </div>
      </div>
    </div>
    <div class="col-12 col-md-5 ">
      <div class="footer-contact-box">
        <div class="row">
          <div class="col-sm-7">
            <div class="footer-contact-header">Contact us</div>
            <div class="info-row"><a href="mailto:info@cryptus.io">info@cryptus.io</a></div>
            <div class="info-row"><div class="footer-name">OpenGate Technology OÜ</div></div>
            <div class="info-row">Narva Road 7B, 10117 Tallinn Estonia</div>
          </div>
          <div class="col col-logo">
            <div class="footer-logo">
              <svg>
                <use xlink:href="./img/svg/booster.svg#logo"></use>
              </svg>
            </div>
            <div class="copyright">&copy; 2021. All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



