import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContextModel, RegistrationinfoModel } from '@app/models';
import { AuthService } from '@app/core/services/auth.service';

export const statuses = {
  NOREG: 'noreg',
  VERIFICATION: 'verification',
  REGISTRATION: 'registration',
  REGISTERED: 'registered',
  DECLINED: 'declined',
  COMPLETED: 'completed',
  ONREVIEW: 'onreview',
  STEP: 'step',
  EXTERNAL_VERIFICATION: 'external_verification',
};

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  constructor(private router: Router, private authService: AuthService) {}

  // registration, noreg, verification, declined, registered
  redirectToPage(context: ContextModel) {
    switch (context.status) {
      case statuses.NOREG:
        this.router.navigate(['/welcome']);
        break;
      case statuses.VERIFICATION:
        // когда регу прошли, но её ещё рассматривает оператор.
        this.router.navigate(['/private/completed']);
        break;
      case statuses.REGISTRATION:
        // this.router.navigate(['/dashboard']);
        // if (context.additionalInfo.redirectMethod === undefined) {
        // в процессе регистрации. В additionalInfo будет доп информация куда перевести
        // id нет!!!
        // http://localhost:4200/private/view/1630
        // }

        const url = this.getRegistrationUrl(context.registrationinfo);

        console.log('redirectMethod ', url);
        this.router.navigate([url]);
        break;

      case statuses.REGISTERED:
        // всё, зарегали, можно показывать дешборд
        this.router.navigate(['/dashboard']);
        break;
      case statuses.DECLINED:
        this.router.navigate(['/private/declined']);
        break;
      case statuses.COMPLETED:
        this.router.navigate(['/private/completed']);
        break;
      case statuses.ONREVIEW:
        this.router.navigate(['/private/completed']);
        break;
      default:
        this.router.navigate(['/welcome']);
        break;
    }
  }

  redirectToPageChangePassword(res: string) {
    this.router.navigate(['/user/change-password'], { queryParams: { res } });
  }

  getRegistrationUrl(registrationinfo: RegistrationinfoModel): string {
    let url = '';
    const registrationStatus = registrationinfo.status.toLowerCase();
    switch (registrationStatus) {
      case statuses.STEP:
        url = this.getStepPageUrl(registrationinfo);
        break;
      case statuses.EXTERNAL_VERIFICATION:
        url = `private/view/${registrationinfo.objectid}`;
        break;
      case statuses.ONREVIEW:
        url = `private/completed`;
        break;
      case statuses.DECLINED:
        url = `private/declined`;
        break;
      case statuses.COMPLETED:
        url = `private/completed`;
        break;
      default:
        break;
    }
    return url;
  }

  getStepPageUrl(registrationinfo: RegistrationinfoModel): string {
    const documenttype = registrationinfo.documenttype;
    const methodGUID = registrationinfo.methodguid;
    const objectid = registrationinfo.objectid;
    let page = '';
    switch (methodGUID) {
      case '{682E9153-EB20-440D-98C2-C35AFEA7F307}':
        // form
        page = '/private/contacts/confirm/';
        break;
      case '{26EFF5A6-AB1E-4C1C-8D7F-CF092CF1B16F}':
        // form
        page = '/private/personal/';
        break;
      case '{9D00829D-ACB0-47FE-8C2D-C7F3B3BA29F9}':
        // form
        page = '/private/residence/';
        break;
      case '{C24E4021-D681-42D9-A44F-C1A89FA9CCE7}':
        // form
        page = '/private/occupation/';
        break;
      case '{E11E694B-0271-4556-9D0B-7DF342D9A7C3}':
        // form
        page = '/private/funds/';
        break;
      case '{657719FC-D402-4127-8F48-5EBB3B572139}':
        // form
        page = '/private/approve/';
        break;
      case '{A501EFDF-A791-4E20-9CB8-31B968AEF39A}':
        // form
        page = '/private/approve/personal/';
        break;
    }
    return `${page}${documenttype}/${objectid}/${methodGUID}/form`;
  }

  // TODO Найти более красивое решение с обработкой статусов и редиректами
  externalRedirectToPage(status: string, objectid?: string) {
    console.log('externalRedirectToPage', status, objectid);
    switch (status) {
      case statuses.NOREG:
        this.authService.logout();
        break;
      case statuses.STEP:
        this.authService.logout();
        break;
      case statuses.VERIFICATION:
        this.router.navigate(['/private/completed']);
        break;
      case statuses.EXTERNAL_VERIFICATION:
        this.router.navigate([`private/view/${objectid}`]);
        break;
      case statuses.REGISTRATION:
        this.authService.logout();
        break;
      case statuses.REGISTERED:
        this.authService.logout();
        break;
      case statuses.DECLINED:
        this.router.navigate(['/private/declined']);
        break;
      case statuses.COMPLETED:
        this.router.navigate(['/private/completed']);
        break;
      case statuses.ONREVIEW:
        this.router.navigate(['/private/completed']);
        break;
      default:
        this.authService.logout();
        break;
    }
  }
}
