import { Component, OnInit } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AppFormData} from '@app/library/form/form.model';
import {FormsData} from '@app/shared/forms/forms.data';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  public formData: AppFormData = FormsData.Contact;

  constructor() {
  }

  ngOnInit(): void {
  }

  public submit(form?: FormGroup): void {
    console.log(form.value);
  }
}
