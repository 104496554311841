import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from '@app/core/services';
import {UserService} from '@app/core/services/user.service';
import {statuses} from '@app/core/services/context.service';
import {untilDestroyed} from '@ngneat/until-destroy';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService, private userService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.userAuthCheck();
    return this.authService.isGuest
      .pipe(
        take(1),
        map(isGuest => {
          if (!isGuest) {
            this.userService.getClientStatus().subscribe(result => {
              if (result.status === statuses.REGISTERED) {
                const redirect = route.data && route.data.authRedirect;
                if (redirect) {
                  this.router.navigate([redirect]);
                  return false;
                }
              }
            });
          }

          return true;
        })
      );
  }
}
