import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertModel, AlertType} from '@app/models';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AlertService} from '@app/core/services';
import {NavigationStart, Router} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  alerts: AlertModel[] = [];

  constructor(private alertService: AlertService, private router: Router) {
    this.router.events.pipe(
      untilDestroyed(this)
    ).subscribe(event => {
      if (event instanceof NavigationStart) {
        console.log('NavigationStart');
        this.alerts = [];
      }
    });
  }

  ngOnInit() {
    this.alertService.onAlert().pipe(
      untilDestroyed(this)).subscribe(alert => {
      if (!alert.message) {
        this.alerts = [];
        return;
      }
      this.alerts = [];
      this.alerts.push(alert);
    });
  }

  removeAlert(alert: AlertModel) {
    this.alerts = this.alerts.filter(x => x !== alert);
  }

  cssClass(alert: AlertModel) {
    if (!alert) {
      return;
    }

    switch (alert.type) {
      case AlertType.Success:
        return ' -success';
      case AlertType.Danger:
        return ' -danger';
      case AlertType.Primary:
        return ' -primary';
      case AlertType.Warning:
        return ' -warning';
    }
  }

  ngOnDestroy(): void {
  }
}
