import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {AuthGuard} from '@app/core/guard/auth.guard';
import {ExternalPageComponent} from '@app/views/external-page/external-page.component';
import {TopupStatusComponent} from '@app/views/topup-status/topup-status.component';
import {DocumentComponent} from '@app/views/document/document.component';
import {MainLayoutComponent} from '@app/views/layouts/main-layout/main-layout.component';
import {DefaultLayoutComponent} from '@app/views/layouts/default-layout/default-layout.component';
import {LoginPageComponent} from '@app/views/login/login-page/login-page.component';
import {WelcomePageComponent} from '@app/views/welcome-page/welcome-page.component';
import {NotFoundPageComponent} from '@app/views/not-found-page/not-found-page.component';
import {ContactPageComponent} from '@app/views/contact-page/contact-page.component';
import {RedirectGuard} from '@app/core/guard/redirect.guard';


const routes: Routes = [
  {
    path: '', component: MainLayoutComponent, children: [
      {
        path: '',
        loadChildren: () => import('./modules/index/index.module').then(m => m.IndexModule),
        canActivate: [RedirectGuard],
        data: {
          authRedirect: 'dashboard',
        },
        pathMatch: 'full',
      },
      {path: 'contact', component: ContactPageComponent},
      {path: 'merchant', loadChildren: () => import('./modules/merchant/merchant.module').then(m => m.MerchantModule)}
    ],
  },
  {
    path: '', component: DefaultLayoutComponent, children: [
      {path: '', redirectTo: '/', pathMatch: 'full'},
      {path: 'login', component: LoginPageComponent},
      {path: 'signup', loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule)},
      {path: 'password', loadChildren: () => import('./modules/password/password.module').then(m => m.PasswordModule)},
      {path: 'ui-kit', loadChildren: () => import('./ui-kit/ui-kit.module').then(m => m.UiKitModule)},
    ]
  },
  {
    path: '', component: MainLayoutComponent, canActivateChild: [AuthGuard], children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'external', component: ExternalPageComponent},
      {path: 'topup/status', component: TopupStatusComponent},
      {path: 'welcome', component: WelcomePageComponent},
      {
        path: 'private',
        loadChildren: () => import('./modules/privatereg/private-reg.module').then(m => m.PrivateRegModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {path: 'user', loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)},
      {path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)},
      {path: 'document/:documenttype/:id/:guid', component: DocumentComponent},
    ]
  },
  {
    path: '', component: DefaultLayoutComponent, children: [
      {path: 'not-found', component: NotFoundPageComponent, pathMatch: 'full'},
      {
        path: 'merchant',
        loadChildren: () => import('./modules/merchant/merchant-notifications/merchant-notifications.module').then(m => m.MerchantNotificationsModule)
      }
    ]
  },
  {
    path: '**', redirectTo: '/not-found'
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
