import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorDialogComponent} from '@app/shared/dialog/error-dialog/error-dialog.component';
import {CommonDialogComponent} from '@app/shared/dialog/common-dialog/common-dialog.component';
import {take} from 'rxjs/operators';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {cleanUpUrl} from '@app/shared/utils/url-cutter';

interface BaseDialogData {
  title: string;
  message: string;
}

export interface HttpErrorDialogData {
  error: HttpErrorResponse;
}

export type DialogType = 'http-error' | 'warning' | 'info' | 'error';
export type DialogData = (BaseDialogData | HttpErrorDialogData) & MatDialogConfig;

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(public dialog: MatDialog, private router: Router, private location: Location) {
  }

  public openDialog(type: DialogType, data: DialogData): void {
    if (data) {
      console.log('openDialog :: ', data);
    }

    const config: MatDialogConfig = {data};
    let component: ComponentType<any>;

    switch (type) {
      case 'http-error':
        component = ErrorDialogComponent;
        config['panelClass'] = ['jtx-dialog', '-danger'];
        config['disableClose'] = true;
        break;
      case 'error':
        component = CommonDialogComponent;
        config['panelClass'] = ['jtx-dialog', '-danger'];
        config['disableClose'] = true;
        break;
      case 'info':
        component = CommonDialogComponent;
        config['panelClass'] = ['jtx-dialog', '-success'];
        break;
      case 'warning':
        component = CommonDialogComponent;
        config['panelClass'] = ['jtx-dialog', '-warning'];
        config['disableClose'] = true;
        break;
    }

    this.dialog.open(component, config).afterClosed().pipe(
      take(1)
    )
      .subscribe(() => {
        cleanUpUrl(this.router.url, this.location);
      });
  }

}
