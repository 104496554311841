<div class="form-methods">
  <ng-container *ngFor="let method of methods">
    <button *ngIf="method.caption !== 'Cancel' && method.caption !== 'Check Status' && method.caption !=='Copy'"
            mat-flat-button
            [class]="'btn -lg -accent btn-method method-' + method.guid.toLowerCase()"
            (click)="clickMethod(method); $event.preventDefault()">
      {{method.caption}}
    </button>
  </ng-container>
</div>
