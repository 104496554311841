import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from '@app/core/services';
import {DialogService} from '@app/core/services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router, private authService: AuthService, private dialogService: DialogService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    // this.router.navigate(['']);
    this.authService.userAuthCheck();
    return this.authService.isGuest.pipe(
      take(1),
      map(isAuth => !isAuth));
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.authService.userAuthCheck();
    return this.authService.isGuest.pipe(
      take(1),
      map(isGuest => {

        if (isGuest) {
          console.log('Guard false logout');
          // this.dialogService.openDialog('error', {title: 'Unauthorized', message: 'Your session has expired. Please login again.'});
          this.router.navigate(['/']);
          return false;
        } else {
          console.log('Guard true');
          return true;
        }
      }));
  }
}
