import {Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment';

import {AppFieldOption, AppFormField, FormMode} from '../form.model';
import {FormService} from '../form-services/form.service';
import {ControlService} from '../form-services/control.service';
import {FORM_ERRORS} from '../form-services/form-errors';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnChanges, OnInit {
  @Input() public form: FormGroup;
  @Output() public subtitleHideShow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public mode: FormMode;
  @Input() public field: AppFormField;
  @Output() public changeCalculatedField: EventEmitter<string> = new EventEmitter();
  @ViewChild('inputFile') private inputFile: ElementRef;
  public fieldContainer = false;
  public condition$: BehaviorSubject<any>;
  public fileName = null;
  public passRequirements: PassRequirements = {} as PassRequirements;
  public hidePass = true;
  private isCalcparam: boolean;

  constructor(private formService: FormService,
              private controlService: ControlService,
              @Inject(FORM_ERRORS) private errorMsg) {
  }

  public ngOnChanges(): void {
    // TODO: Чтобы лейбл не скрывался с пустым значением
    if (!this.field.caption) {
      this.field.caption = ' ';
    }
  }

  ngOnInit(): void {
    if (this.field.tag === 'confirm') {
      this.field.hidden = true;
    }
    if (this.field.type === 'attachment') {
      this.fieldContainer = true;
    }
    this.isCalcparam = this.field.calcparam;
    if (this.field.conditions && this.field.conditions.length > 0) {
      this.condition$ = this.formService.condition;

      this.condition$
        .subscribe(result => {
          if (result != null) {
            this.conditionCheck(result.fieldName, result.fieldValue);
          }
        });
    }
    this.passwordHint();
  }

  public noEvent(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  public toggleHidePass(event: MouseEvent): void {
    this.noEvent(event);
    this.hidePass = !this.hidePass;
  }

  public onChangeFile(event): void {
    const files = event.target.files;
    if (files.length) {
      const file = files[0];
      this.form.get(this.field.name + ' caption').setValue(file.name);
      console.log('bobFile', file.name);
      this.fileName = file.name;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.get(this.field.name).setValue(reader.result);
      };
    } else {
      this.form.get(this.field.name + ' caption').setValue(null);
      this.form.get(this.field.name).setValue(null);
      this.fileName = null;
    }
  }

  public deleteFile(event: MouseEvent): void {
    event.preventDefault();
    this.fileName = null;
    this.inputFile.nativeElement.value = '';
    this.form.get(this.field.name + ' caption').setValue(null);
    this.form.get(this.field.name).setValue(null);
  }

  get error() {
    const controlErrors = this.control.errors;

    return this.controlService.getErrorMsg(this.control);
  }

  get control(): AbstractControl {
    return this.form.get(this.field.name);
  }

  public conditionCheck(field: string, value: any) {
    const newValue = value;

    for (const condition of this.field.conditions) {
      if (condition.parentfield === field && Number(condition.value) === Number(newValue)) {
        this.toActionCondition(condition.action);
      }
    }
  }

  public toActionCondition(action: string) {
    switch (action) {
      case 'hide':
        this.field.hidden = true;
        break;
      case 'show':
        this.field.hidden = false;
        break;
      default:
        break;
    }
  }

  public fieldIsChanged(field, event, val?) {
    this.subtitleHideShow.emit(false);
    let value = null;
    val === undefined ? value = event.target.value : value = val;
    if (this.isCalcparam === true) {
      this.getCalculateData(field);
    }
    this.formService.addCondition(field, value);
    this.subtitleHideShow.emit(value);

  }

  public getCalculateData(field: string): void {
    this.changeCalculatedField.emit(field);
  }

  public getValue(): string {
    let value: string;
    switch (this.field.type) {
      case 'date':
        const tmp = moment(this.field.value as string, 'YYYYMMDD');
        value = tmp.format('LL');
        break;
      case 'drop-down collapsible-box':
      case 'drop-down box':
        const val = this.field.value;
        const rrt = (this.field.options as AppFieldOption[]).filter(item => item.key === val);
        value = rrt.length ? rrt[0].value : '';
        break;
      default:
        value = this.field.value as string;
        break;
    }
    return value;
  }

  public passwordHint() {
    if (this.field.name === 'password' || this.field.name === 'newpassword') {
      this.form.get(this.field.name).valueChanges
        .subscribe(result => {
            this.passRequirements.hasNumber = /\d/.test(result);
            this.passRequirements.hasUpper = /[A-Z]/.test(result);
            this.passRequirements.hasLower = /[a-z]/.test(result);
            this.passRequirements.hasMinimum = /.{8,}/.test(result);
            this.passRequirements.hasSpecial = /\W/.test(result);
          }
        );
    }
  }

  public resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
}

interface PassRequirements {
  hasNumber: boolean;
  hasUpper: boolean;
  hasLower: boolean;
  hasMinimum: boolean;
  hasSpecial: boolean;
}
