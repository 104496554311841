import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppStateService } from '@app/core/services/app-state.service';
import { Theme, ThemeData } from '@app/models/theme.model';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themes: BehaviorSubject<Theme[]> = new BehaviorSubject([]);

  constructor(private http: HttpClient,
              private appState: AppStateService) {
    if (!environment.production) {
      this.getData()
        .subscribe(data => {
          this.themes.next(data.themes || []);
          this.setTheme();
          this.checkTheme();
        });
    }
  }

  private getData(): Observable<ThemeData> {
    return this.http.get<ThemeData>(`/assets/data/themes.json`);
  }

  private checkTheme(): void {
    const themeName = this.appState.getThemeStorage();
    this.setTheme(themeName || this.defaultTheme().name);
  }

  private getTheme(name: string): Theme {
    return this.themes
      .getValue().find(item => item.name === name);
  }

  private defaultTheme(): Theme {
    return this.themes
      .getValue().find(item => item.default) || this.themes[0];
  }

  private acceptTheme(theme: Theme): void {
    this.appState.setThemeStorage(theme.name);
    Object.keys(theme.settings).forEach(key => {
      document.documentElement.style.setProperty(key, theme.settings[key]);
    });
  }

  public setTheme(name?: string): void {
    const theme: Theme = name ? (this.getTheme(name) || this.defaultTheme()) : this.defaultTheme();
    this.acceptTheme(theme);
  }

  public getThemes(): Observable<string[]> {
    return this.themes
      .asObservable()
      .pipe(map(item => item.map(theme => theme.name)));
  }

}
