import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NavigationStart, Route, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AlertModel, AlertType} from '@app/models';


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private alert$ = new Subject<AlertModel>();

  constructor(private router: Router) {
    this.alert$.next(null);
  }

  onAlert(): Observable<AlertModel> {
    return this.alert$.asObservable();
  }

  success(message: string, fieldErrors?: FieldErrors[], alertId?: string) {
    const messages = this.getMessages(message, fieldErrors);
    this.alert(new AlertModel({message: messages, type: AlertType.Success, alertId}));
  }

  danger(message: string, fieldErrors?: FieldErrors[], alertId?: string) {
    const messages = this.getMessages(message, fieldErrors);
    this.alert(new AlertModel({message: messages, type: AlertType.Danger, alertId}));
  }

  primary(message: string, fieldErrors?: FieldErrors[], alertId?: string) {
    const messages = this.getMessages(message, fieldErrors);
    this.alert(new AlertModel({message: messages, type: AlertType.Primary, alertId}));
  }

  // {message: 'text message'}
  warning(message: string, fieldErrors?: FieldErrors[], alertId?: string) {
    const messages = this.getMessages(message, fieldErrors);
    this.alert(new AlertModel({message: messages, type: AlertType.Warning, alertId}));
  }

  alert(alert: AlertModel) {
    this.alert$.next(alert);
  }

  getMessages(message: string, fieldErrors?: FieldErrors[]): string[] {
    const messages: string[] = [];
    if (fieldErrors !== undefined && fieldErrors.length) {
      for (const item of fieldErrors) {
        const msg = `${item.key} Msg:: ${item.value}`;
        messages.push(msg);
      }
    } else {
      messages.push(message ? message : 'Request sent successfully');
    }

    return messages;
  }
}

export class FieldErrors {
  key: string;
  value: string;

  constructor(key?: string, value?: string) {
    this.key = key;
    this.value = value;
  }
}
