import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {AppCalculated, AppFormData, AppFormField, FormMode, InitFormData} from '../form.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-form-groups',
  templateUrl: './form-groups.component.html',
  styleUrls: ['./form-groups.component.scss']
})
export class FormGroupsComponent implements OnChanges, OnInit {
  @Input() public initData: InitFormData;
  @Input() public formData: AppFormData;
  @Input() public mode: FormMode;
  public subtitle: boolean;
  public currentUrl = '';

  constructor(private http: HttpClient, private router: Router) {
  }

  public ngOnChanges(): void {
    let found: boolean;
    for (const group of this.formData.groups) {
      for (const field of group.fields) {
        if (field.calcparam) {
          found = true;
          break;
        }
      }
    }

    if (found) {
      this.getCalculatedData();
    }


  }

  ngOnInit(): void {
    this.currentUrl = this.router.url;
  }


  public subtitleHideShow(event: boolean) {
    this.subtitle = event;
  }


  public getCalculatedData(event: string = ''): void {
    if (this.initData.guid === 'NEW') {
      const url = `document/${this.initData.documenttype}/${this.initData.id}/${this.initData.guid}/calculate`;
      let params = new HttpParams();
      Object.keys(this.formData.form.value).forEach(item => {
        const value = this.formData.form.value[item] || 0;
        params = params.append(item, value);
      });
      if (event) {
        params = params.append('changedFieldName', event);
      }

      this.http.get<AppCalculated>(url, {params})
        .subscribe(result => {
          for (const item of result.values) {
            if (item.field in this.formData.form.controls) {
              this.formData.form.get(item.field).setValue(item.value);

              // ОСТОРОЖНО костыль!!!
              if (item.field === 'confirmriskytransaction') {
                this.formData.form.get(item.field).setValue(!!+item.value);

                // tslint:disable-next-line:no-shadowed-variable
                const allFields = (this.formData.groups || []).reduce((result, group) => {
                  result = [...result, ...group.fields];
                  return result;
                }, []);

                const risky = allFields.find((f) => f.name === item.field);
                risky.hidden = !Boolean(+item.options.visible);
              }
            }
            if (item.field.substr(0, 6) === 'scale_') {
              const fieldName = item.field.replace('scale_', '');
              const field = this.findField(fieldName, this.formData);
              if (field) {
                field.format = '1.' + item.value + '-' + item.value;
              }
            }
          }
        });
    }


  }

  private findField(fieldName: string, data: AppFormData): AppFormField {
    let foundField: AppFormField;
    for (const group of data.groups) {
      for (const field of group.fields) {
        if (field.name === fieldName) {
          foundField = field;
          break;
        }
      }
    }

    return foundField;
  }

}
