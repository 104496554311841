import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

import { PrepareForSignature, SignFormData } from '../form.model';
import { FormService } from '../form-services/form.service';

@Component({
  selector: 'app-form-sign',
  templateUrl: './form-sign.component.html',
  styleUrls: ['./form-sign.component.scss']
})
export class FormSignComponent implements OnInit {
  @Input() public data: SignFormData;
  @Output() public signEvent: EventEmitter<any> = new EventEmitter();
  public initWait = 30;
  public wait: number;

  constructor(private formService: FormService) {
  }

  ngOnInit(): void {
  }

  private startTimer(): void {
    this.wait = this.initWait;
    interval(1000)
      .pipe(take(this.initWait))
      .subscribe(val => {
        this.wait = this.initWait - val - 1;
      });
  }

  public clearSMSField(): void {
    this.data.signatures
      .filter(item => item.type === 'SMSPASSWORD')
      .forEach(item => item.value = '');
  }

  public changeField() {
    this.signEvent.emit(this.data.signatures
      .map(item => {
        return { num: item.num, value: item.value };
      }));
  }

  public sendSMS(): void {
    const signature = this.data.signatures.find(item => item.type === 'SMSPASSWORD');
    if (signature) {
      const postData: PrepareForSignature = {} as PrepareForSignature;
      postData.id = this.data.id;
      postData.documenttype = this.data.documenttype;
      postData.signatureNum = signature.num;
      postData.guid = this.data.guid;

      this.formService.sendSMS(postData)
        .subscribe(() => this.clearSMSField());

      this.startTimer();
    }
  }
}
