import {InjectionToken} from '@angular/core';

export const defaultErrors = {
  required: (error) => `This field is required`,
  email: (error) => `Email This field is required`,
  minlength: ({ requiredLength, actualLength }) => `Expect ${requiredLength} but got ${actualLength}`,
  nameError: (error) => `User already exists`,
  mustMatch: (error) => `Password mismatch`,
  matDatepickerParse: (error) => `Invalid date format`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
