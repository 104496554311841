import {Component, OnInit} from '@angular/core';
import {AuthService} from '@app/core/services';
import {UserService} from '@app/core/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    const isGuest = this.authService.userAuthCheck();

    if (!isGuest) {
      this.userService.getClientStatus().subscribe(
        response => {
          this.authService.setStatusAuthUser(response.status);
        }
      );
    }
  }
}
