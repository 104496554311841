<div class="row" *ngFor="let group of formData.groups">

  <div *ngIf="!group.hideCaption" class="col-md-12">
    <h1><span class="subtitle">{{group.caption}}</span></h1>
  </div>

  <ng-container *ngFor="let field of group.fields">
    <app-form-field class="{{field.styles || 'col-md-12'}}"
                    [field]="field"
                    [form]="formData.form"
                    (changeCalculatedField)="getCalculatedData($event)"
                    [mode]="mode"
                    (subtitleHideShow)="subtitleHideShow($event)"
    ></app-form-field>
  </ng-container>

</div>
