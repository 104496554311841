import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppFormMethod } from '../form.model';

@Component({
  selector: 'app-form-methods',
  templateUrl: './form-methods.component.html',
  styleUrls: ['./form-methods.component.scss']
})
export class FormMethodsComponent implements OnInit {
  @Input() public methods: AppFormMethod[];
  @Output() public activateMethod: EventEmitter<AppFormMethod> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public clickMethod(method: AppFormMethod) {
    this.activateMethod.emit(method);
  }

}
