import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService, AuthService } from '@app/core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContextService } from '@app/core/services/context.service';
import { ContextModel } from '@app/models';
import { FormsData } from '@app/shared/forms/forms.data';
import { AppFormData } from '@app/library/form/form.model';
import {animate, style, transition, trigger} from '@angular/animations';

@UntilDestroy()
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ opacity: 0 }),
            animate('1s ease-out',
              style({ opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ opacity: 1 }),
            animate('1s ease-in',
              style({ opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class LoginPageComponent implements OnInit {
  public formData: AppFormData = FormsData.SignIn;

  constructor(private authService: AuthService,
              private contextService: ContextService,
              private alertService: AlertService) {
  }

  ngOnInit() {
  }

  formSubmit(form?: FormGroup): void {
    this.authService.login(form.value)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
          this.alertService.warning(result.message, result.fieldErrors);
          if (+result.result === 1) {
            const context = new ContextModel(result.status, result.registrationinfo);
            this.contextService.redirectToPage(context);
          }
        },
        () => {
        },
        () => {
          console.log('*_*_* complete *_*_*');
          form.reset();
        });
  }

}
