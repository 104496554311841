import {Injectable} from '@angular/core';
import {ApiService} from '@app/core/services/api.service';
import {HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Transaction, TransactionsResponse} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {
  public refreshTransactionsSubject = new BehaviorSubject<boolean>(false);

  constructor(private api: ApiService) {
  }

  public getBlotterTransactions(params: HttpParams): Observable<TransactionsResponse<Transaction>> {
    return this.api.get('exchangeOrders', params);
  }

  public cancelOrder(orderId: number): Observable<any> {
    const formData = {
      documenttype: 'CRYPTO_Exchange',
      id: orderId,
      guid: '{4107E28B-E4B9-4AFA-B157-ADD4AC5E461E}',
      fields: []
    };

    return this.api.post('document', formData);
  }

  public calcOrder(params: HttpParams): Observable<any> {
    return this.api.get(`document/CRYPTO_Exchange/0/NEW/calculate`, params);
  }

}
