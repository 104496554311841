<div>
  <div class="-status" mat-dialog-title>
    <!-- <div class="icon">
      <svg>
        <use xlink:href="./img/svg/booster.svg#close"></use>
      </svg>
    </div> -->

    <div class="status-name" >
      {{data.title}}
    </div>
  </div>

  <div class="msg" mat-dialog-content>
    <p class="-lg">{{data.message}}</p>
  </div>

  <div mat-dialog-actions>
    <button mat-flat-button class="btn -lg -accent" (click)="closeDialog()">Close</button>
  </div>
</div>
