<div class="overlay-navigation">
  <div class="overlay-navigation__header">
  </div>
  <div class="overlay-navigation__content">
    <app-navbar
      direction="column"
      (overlayClose)="close()"
      [isRegistered]="isRegistered"
      [isGuest]="isGuest"
    ></app-navbar>
  </div>
</div>
