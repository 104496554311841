import { AppFormData } from '@app/library/form/form.model';

export class FormsData {

  static SignIn: AppFormData = {
    groups: [{
      fields: [
        { name: 'username', caption: 'E-mail', type: 'string', required: true, canchange: true },
        { name: 'password', caption: 'Password', type: 'password', required: true, canchange: true },
      ]
    }],
  };

  static Contact: AppFormData = {
    groups: [{
      fields: [
        { name: 'firstname', caption: 'First Name', type: 'string', required: true, canchange: true },
        { name: 'lastname', caption: 'Last Name', type: 'string', required: true, canchange: true },
        { name: 'email', caption: 'Email', type: 'string', required: true, canchange: true },
        { name: 'theme', caption: 'Theme', type: 'string', required: true, canchange: true },
        { name: 'comment', caption: 'Comment', type: 'text', required: true, canchange: true },
        { name: 'recaptcha', type: 'recaptcha', required: true },
      ]
    }],
  };

}
