import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerComponent } from '@app/shared/toast/toast-container/toast-container.component';
import { StepsComponent } from '@app/shared/steps/steps.component';
import { ActionsGroupComponent } from '@app/shared/actions-group/actions-group.component';
import { FileUploadComponent } from '@app/shared/file-upload/file-upload.component';
import { AlertComponent } from '@app/shared/alert/alert.component';
import { NgxMaskModule } from 'ngx-mask';
import { ErrorDialogComponent } from './dialog/error-dialog/error-dialog.component';
import { CommonDialogComponent } from './dialog/common-dialog/common-dialog.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { NavbarComponent } from './navbar/navbar.component';
import { OrderBookComponent } from '@app/shared/order-book/order-book.component';
import { ChartComponent } from '@app/shared/chart/chart.component';
import { OverlayNavigationComponent } from './overlay-navigation/overlay-navigation.component';
import { CollapsibleBoxComponent } from './collapsible-box/collapsible-box.component';
import { AccessCodeDialogComponent } from '@app/shared/dialog/access-code-dialog/access-code-dialog.component';

@NgModule({
  declarations: [
    AlertComponent,
    StepsComponent,
    ActionsGroupComponent,
    ErrorDialogComponent,
    CommonDialogComponent,
    LoadingSpinnerComponent,
    ToastContainerComponent,
    FileUploadComponent,
    PaginatorComponent,
    NavbarComponent,
    OrderBookComponent,
    ChartComponent,
    OverlayNavigationComponent,
    CollapsibleBoxComponent,
    AccessCodeDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    RouterModule,
    NgbToastModule,
    NgbModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
  ],
  exports: [
    RouterModule,
    NgbModule,
    LoadingSpinnerComponent,
    AlertComponent,
    FileUploadComponent,
    ToastContainerComponent,
    ErrorDialogComponent,
    CommonDialogComponent,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    NgbToastModule,
    StepsComponent,
    ActionsGroupComponent,
    NgxMaskModule,
    PaginatorComponent,
    NavbarComponent,
    OrderBookComponent,
    ChartComponent,
    OverlayNavigationComponent,
    CollapsibleBoxComponent,
    AccessCodeDialogComponent,
  ],
  entryComponents: [ErrorDialogComponent, CommonDialogComponent],
})
export class SharedModule {}
