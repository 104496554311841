import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpStateService } from '@app/core/services/http-state.service';
import { AuthService } from '@app/core/services';
import { environment } from '@env/environment';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private httpStateService: HttpStateService,
    private authService: AuthService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const session = this.authService.getSession();

    if (session !== undefined) {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bob',
          'x-sid': session,
        }
      });
    }
    if (request.url.split('/')[1] === 'assets') {
      return next.handle(request);
    }
    return next.handle(request.clone({ url: environment.apiServer + request.url }));
  }
}
