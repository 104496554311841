import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {
  isDark = true;

  constructor() {
  }

  ngOnInit() {
  }

  toggleTheme(): void {
    this.isDark = !this.isDark;
  }
}
