import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {LoadingSpinner, Size} from '@app/models/loading-spinner.model';
import {LoadingSpinnerService} from '@app/core/services/loading-spinner.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  @Input() fullScreen: boolean;
  @Input() size: Size;
  @Input() color: string;

  spinner: LoadingSpinner = new LoadingSpinner();

  sizes = {
    small: 40,
    medium: 70,
    large: 100,
  };

  constructor(private spinnerService: LoadingSpinnerService) {
    this.fullScreen = this.fullScreen || false;
    this.size = 'medium';
  }

  private isMobileDevice(): boolean {
    const innerWidth = window.innerWidth;
    return innerWidth < 992;
  }

  ngOnInit() {
    this.setDefaultOptions();
    this.calcSize();
    this.spinnerService
      .getSpinner()
      .pipe(untilDestroyed(this))
      .subscribe((spinner: LoadingSpinner) => {
        Object.assign(this.spinner, spinner);
      });
  }


  private setDefaultOptions() {
    this.spinner = new LoadingSpinner({
      fullScreen: this.fullScreen,
      size: this.size,
    });
  }

  private calcSize(): number {
    if (this.spinner.size === 'small' && this.isMobileDevice()) {
      return this.sizes.small = 20;
    } else {
      return this.sizes.small;
    }
  }


  ngOnDestroy() {
  }
}
