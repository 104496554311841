import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RequisiteService} from '@app/core/services/requisite.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WalletModel} from '@app/models/wallet.model';
import {LoadingSpinnerService} from '@app/core/services/loading-spinner.service';
import {delay} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-bitcoin-top-up',
  templateUrl: './bitcoin-top-up.component.html',
  styleUrls: ['./bitcoin-top-up.component.scss'],
  providers: [LoadingSpinnerService]
})
export class BitcoinTopUpComponent implements OnInit {
  public requisites: WalletModel;
  public copied = false;

  constructor(public dialogRef: MatDialogRef<BitcoinTopUpComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private requisiteService: RequisiteService,
              private spinnerService: LoadingSpinnerService) {
  }

  ngOnInit() {
    this.getRequisites();
  }

  public getRequisites(): void {
    this.spinnerService.show();
    this.requisiteService.getWalletlist()
      .pipe(untilDestroyed(this), delay(1000))
      .subscribe(result => {
        this.spinnerService.hide();
        this.requisites = result[0][0];
      });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

}
