import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BitcoinStatement, EuroStatement, StatementResponse } from '@app/models/statement.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private api: ApiService) {
  }

  getBitcoinTransfer(): Observable<any> {
    return this.api.get(`document/CRYPTO_Send/0/NEW/form`);
  }

  public getCurrencyStatement(accountId: string,params: HttpParams): Observable<StatementResponse<any>> {
    return this.api.get(`account/${accountId}/statement`, params);
  }

}
