<div class="container">
  <div class="steps-container row">

    <ng-container *ngFor="let step of counter; let i = index">
      <div class="col step-wrap" [ngClass]="{'active': (i+1) <= activeStep }">
        <div class="-step-number">
          <div class="markStep" *ngIf="(i+1) <= activeStep && (i+1) !== activeStep; else varNumber">
            <svg>
              <use xlink:href="./img/svg/booster.svg#mark"></use>
            </svg>
          </div>
          <ng-template #varNumber>
            <div class="-counter">{{i + 1}}</div>
          </ng-template>

        </div>
        <ng-container *ngIf="(i+1) <= activeStep  else emptyBar">
          <div class="-progress-bar">
            <ngb-progressbar type="success" [value]="formIsFilled" height="8px"></ngb-progressbar>
          </div>
        </ng-container>
        <ng-template #emptyBar>
          <div class="-bar"></div>
        </ng-template>

      </div>
    </ng-container>

  </div>
</div>


