<div class="bitcoin-top-up-dialog">
  <div class="dialog-title">
    <h3>Bitcoin Top Up</h3>
  </div>

  <app-loading-spinner></app-loading-spinner>

  <div class="msg" mat-dialog-content *ngIf="requisites">
    <table class="table">
      <tbody>
      <tr>
        <th scope="row">Address</th>
        <td class="d-flex align-items-center justify-content-between flex-wrap">
          {{requisites?.address}}
          <button type="button" class="btn -action"
                  [cdkCopyToClipboard]="requisites?.address"
                  (click)="$event.preventDefault();
                  copied = true">
            <span class="copied-text" *ngIf="copied">Copied!</span>
            <svg>
              <use xlink:href="./img/svg/booster.svg#copy"></use>
            </svg>
          </button>
        </td>
      </tr>
      <tr>
        <th scope="row">Currency</th>
        <td>{{requisites?.currcodechr}}</td>
      </tr>
      <tr>
        <th scope="row">Wallet</th>
        <td>{{requisites?.designation}}</td>
      </tr>
      <tr>
        <th scope="row">QR</th>
        <td>
          <qrcode *ngIf="requisites" [qrdata]="requisites?.address" [width]="256"></qrcode>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div mat-dialog-actions>
    <button mat-flat-button class="btn -lg -accent" (click)="closeDialog()">Close</button>
  </div>
</div>
