<div class="container">
  <div class="row">
    <div class="col-md-8">
      <div class="toggle-view -in-main-page">
        <h1>Welcome to Cryptus</h1>
        <!--<h2><span class="subtitle">or</span></h2>
        <button routerLink="/signup" class="btn -lg -secondary-corner"><span>Sign up</span></button>-->
      </div>

    </div>
  </div>

  <div class="row">
    <div class="col-md-7">
      <h1><span class="subtitle">Please select account type</span></h1>
      <mat-tab-group>
        <mat-tab label="Private">
          <p>Before you can start using Cryptus, you will need to complete the following steps:</p>
          <p>1. Enter your info and upload documents</p>
          <p>2. Provide basic financial information</p>
          <p>3. Enable extra security</p>
          <div class="-bottom">
            <button routerLink="/private/contacts/KYC_FullRegistration/0/NEW/form" mat-flat-button class="btn -lg -accent">Next</button>
<!--            <button routerLink="/private/contacts/KYC_FullRegistration/0/NEW/metadata" mat-flat-button class="btn -lg -accent">Next</button>-->
          </div>
        </mat-tab>
        <mat-tab label="Corporate">
          <p>Corporate registration will be enabled soon.</p>
          <div class="-bottom">
<!--            <button routerLink="/dashboard"  mat-flat-button class="btn -lg -accent"><span>Dashboard</span></button>-->
<!--            <button routerLink="/private/personal" mat-flat-button class="btn -lg -accent">Next</button>-->
          </div>

        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- END col-md-6 -->

  </div>


</div>
