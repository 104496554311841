import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  constructor() {
  }

  @Input() steps: number;
  @Input() activeStep: number;
  @Input() formIsFilled: number;
  ngOnInit() {
    console.log('Steps:', this.steps, this.formIsFilled);
  }

  get counter(): Array<number> {
    return new Array(this.steps);
  }

}
