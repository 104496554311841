<form *ngIf="formData?.form" [formGroup]="formData.form" (ngSubmit)="formSubmit()" (keydown)="handleKeyDown($event)"
      class="{{formClass}}">
  <app-form-groups
    [initData]="initData"
    [formData]="formData"
    [mode]="mode"></app-form-groups>

  <app-form-sign *ngIf="signFormData.signatures" [data]="signFormData" (signEvent)="changeSign($event)"></app-form-sign>

  <div class="-in-row  -bottom-section">
    <app-form-methods [methods]="methods" (activateMethod)="activateMethod($event)"></app-form-methods>
    <ng-content></ng-content>
    <button *ngIf="prevPageUrl" type="submit" mat-flat-button class="btn -lg -accent-stroked"
            [routerLink]="prevPageUrl">
      Back
    </button>

    <button type="submit" mat-flat-button class="btn -lg -accent" [disabled]="disabled">
      {{submitButtonName || (mode === 'form' ? 'Submit' : 'Confirm')}}
    </button>
    <div *ngIf="currentUrl !=='/login' && currentUrl === '/'" class="-in-row forgot-pass-container">
      <p class="-lg">Forgot your password? <a routerLink="/password/recovery">Click Here</a></p>
    </div>
  </div>

</form>
