import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginPageComponent} from '@app/views/login/login-page/login-page.component';
import {WelcomePageComponent} from '@app/views/welcome-page/welcome-page.component';
import {NotFoundPageComponent} from '@app/views/not-found-page/not-found-page.component';
import {SharedModule} from '@app/shared';
import {DefaultLayoutComponent} from '@app/views/layouts/default-layout/default-layout.component';
import {FooterLayoutComponent} from '@app/views/layouts/footer-layout/footer-layout.component';
import {MainLayoutComponent} from '@app/views/layouts/main-layout/main-layout.component';
import {UserWidgetComponent} from '@app/views/user-widget/user-widget.component';
import {ExternalPageComponent} from '@app/views/external-page/external-page.component';
import {TopupStatusComponent} from '@app/views/topup-status/topup-status.component';
import { DocumentComponent } from './document/document.component';
import { AppFormModule } from '@app/library/form/form.module';
import { ContactPageComponent } from '@app/views/contact-page/contact-page.component';

@NgModule({
  declarations: [
    MainLayoutComponent,
    DefaultLayoutComponent,
    FooterLayoutComponent,
    LoginPageComponent,
    WelcomePageComponent,
    NotFoundPageComponent,
    UserWidgetComponent,
    ExternalPageComponent,
    TopupStatusComponent,
    DocumentComponent,
    ContactPageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AppFormModule,
  ],
  exports: [
    MainLayoutComponent,
    DefaultLayoutComponent,
    LoginPageComponent,
    WelcomePageComponent,
    NotFoundPageComponent,
  ]
})
export class ViewModule {
}
