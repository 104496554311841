import {Injectable} from '@angular/core';
import {ApiService} from '@app/core/services/api.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {ResultModel} from '@app/models';
import {AuthService} from '@app/core/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class PreRegistrationService {

  constructor(private api: ApiService, private authService: AuthService) {
  }

  preRegistration(data): Observable<any> {
    console.log('Получили данные', data);
    return this.api.post('preregistration', data).pipe(
      tap(result => {
        console.log('PreRegistration Service ', result);
      })
    );
  }

  activate(data): Observable<any> {
    console.log('Получили данные', data);
    return this.api.post('preregistration/activate', data);
  }
}
