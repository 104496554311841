import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '@app/core/services/dialog.service';
import { AuthService } from '@app/core/services';

export const ERROR_INTERCEPTOR_SKIP_HEADER = 'X-Skip-Error-Interceptor';

enum statuses {
  success = 1,
  accessCode = 6,
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private dialogRef: MatDialog,
    private authService: AuthService
  ) {}

  private handleAuthError(error: HttpErrorResponse) {
    if (
      error.error &&
      error.error.errtext &&
      error.error.errtext === 'session.expired'
    ) {
      const sub = this.authService.refreshSession().subscribe((result) => {
        if (+result.result === statuses.success) {
          this.authService.userLogsIn(result);
          sub.unsubscribe();
          window.location.reload();
        }
      });
    } else {
      this.dialogRef.closeAll();
      this.authService.logout();
      this.dialogService.openDialog('http-error', { error });
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.headers.has(ERROR_INTERCEPTOR_SKIP_HEADER)) {
      const headers = request.headers.delete(ERROR_INTERCEPTOR_SKIP_HEADER);
      return next.handle(request.clone({ headers }));
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 400:
              console.log('400 Error', error);
              if (!((error.error?.fieldErrors || []).length > 0)) {
                this.dialogService.openDialog('http-error', { error });
              }
              break;
            case 401:
              console.log('401 Error');
              this.handleAuthError(error);
              break;
            case 403:
              console.log('403 Error');
              this.authService.logout();
              this.dialogService.openDialog('http-error', { error });
              break;
            default:
              console.log('default Error');
              this.dialogService.openDialog('http-error', { error });
          }
        }
        return throwError(error);
      })
    );
  }
}
