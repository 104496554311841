<div class="container">
 <!-- <div class="row">
    <div class="col-md-12">
      <div class="toggle-view">
        <h1>Bitcoin Transfer</h1>
      </div>

    </div>
  </div>-->
  <div class="row">
    <div class="col-md-12">
      <app-form *ngIf="correct else noData" [initData]="data"
                     [mode]="data.guid === 'VIEW' ? 'view' : 'form'"></app-form>
      <ng-template #noData>
        <h3>No document data</h3>
      </ng-template>
    </div>
  </div>

</div>
